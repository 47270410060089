import React from 'react';
import Footer from '../Footer/Footer';

const Residential1Mobile = (props) => {

  return (
    <div className="mob">
      {/* flag */}
      <div style={{ width: "100%", background: "#ffffff" }}>
        <div style={{ height: "100%", padding: "5px 0px 5px 0px" }}>
          <center>
            <img
              src="/art/combologo.png"
              alt="ADT Offer from Capital One"
              style={{ height: 45 }}
            />
          </center>
        </div>
        <div align="left" style={{ backgroundColor: "#005dab" }}>
          <div style={{ padding: 30 }}>
            <div
              className="monty"
              style={{ color: "#ffffff", fontSize: 24, fontWeight: 600 }}
            >
              Welcome Capital One
              <span style={{ fontWeight: 300, fontSize: 15 }}>®</span> Business
              Deals<span style={{ fontWeight: 300, fontSize: 15 }}>®</span>{" "}
              Member!
            </div>
            <div
              className="monty"
              style={{
                marginTop: "15px",
                color: "#ffffff",
                fontSize: "18px",
                lineHeight: "24px",
                fontWeight: 300
              }}
            >
              Homeowners - Get a FREE* state-of-the-art smart security system plus
              your choice of 2 FREE Premium Upgrades (up to $1,350 total value) to
              protect your home and your home business.
            </div>
            <div
              className="monty"
              style={{
                marginTop: "6px",
                color: "#86a3d7",
                fontSize: "12px",
                lineHeight: "15px",
                fontWeight: 300
              }}
            >
              *$99 installation fee and 36-month agreement (24-month in CA)
              required. Early&nbsp;termination&nbsp;fee&nbsp;applies.
            </div>
            <div style={{ marginTop: 20 }} />
            <form onSubmit={props.nextPage}>
              <input
                type="tel"
                name="zip"
                maxLength={5}
                placeholder="ZIP Code"
                data-corners="false"
                value={props.zipCode}
                onChange={(e) => props.setZipCode(e.target.value)}
                style={{ width: 100 }}
              />
              <button
                className="greenhover"
                disabled={!(/[0-9]{5}/).test(props.zipCode)}
                style={{ marginLeft: 10, fontSize: 10 }}
              >
                Submit
              </button>
            </form>
            <div
              className="monty"
              style={{
                marginTop: "20px",
                color: "#ffffff",
                fontSize: "12px",
                lineHeight: "30px",
                fontWeight: 300,
                letterSpacing: "0.02em"
              }}
            >
              <img
                src="/art/check.png"
                alt="Check it out"
                style={{ margin: "0px 8px -5px 0px" }}
              />
              FREE Home Security System ($850 value)
              <br />
              <img
                src="/art/check.png"
                alt="Check it out"
                style={{ margin: "0px 8px -5px 0px" }}
              />
              FREE Bonus Equipment (up to $500 value)
              <br />
              <img
                src="/art/check.png"
                alt="Check it out"
                style={{ margin: "0px 8px -5px 0px" }}
              />
              Choose one video camera and one smart device
              <br />
              <img
                src="/art/check.png"
                alt="Check it out"
                style={{ margin: "0px 8px -5px 0px" }}
              />
              24/7 Professional Monitoring by the world's #1 provider
              <br />
            </div>
          </div>
        </div>
        <div
          className="montylegal"
          style={{ padding: "20px 30px 20px 30px", textAlign: "center" }}
        >
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Residential1Mobile;
