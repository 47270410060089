import React from 'react';
import Footer from '../Footer/Footer';

const SmallBiz2Desktop = (props) => {

  const isDisabled = () => {
    const firstName = (/.{2,}/).test(props.firstName);
    const lastName = (/.{2,}/).test(props.lastName);
    const email = (/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/).test(props.email);
    const phone = (/^(?:\+1)?[2-9]\d{9}$/).test(props.phone);
    return (!(firstName && lastName && email && phone));
  }

  return (
    <center>
      <div className="pc" style={{ width: '100%', maxWidth: '1200px', marginBottom: '30px' }}>
        {/* flag */}
        <div style={{ width: '100%', height: '90px', background: '#ffffff' }}>
          <div style={{ height: '100%', paddingBottom: '0px' }}>
            <div style={{ float: 'left', width: '50%' }} align="left"><img src="/art/74c.jpg" alt="ADT Authorized Dealer" style={{ height: '45px', padding: '23px 0px 0px 53px' }} /></div>
            <div style={{ float: 'left', width: '50%' }} align="right"><img src="/art/cospringlogo.jpg" alt="Capital One Bsuiness Deals" style={{ width: '250px', padding: '23px 90px 0px 0px' }} /></div>
            <div style={{ clear: 'both' }} />
          </div>
          <div align="left">
            <div style={{ display: 'inline-block', width: '42%', maxWidth: '500px', height: '520px', verticalAlign: 'top', paddingTop: '30px' }} align="center"><img src="/art/basesmb.jpg" style={{ width: '90%', maxWidth: '450px' }} /><br />
              <div className="montylegal" style={{ width: '85%', padding: '10px 25px 10px 25px' }}>ADT Small Business Security System includes 1 Command Control Panel, 3 door/window sensors, 1 motion sensor, 3 indoor cameras, battery backup, cellular communication, mobile application, window stickers and outdoor sign.</div>
              <div className="monty" style={{ width: '60%', margin: '30px 0px 0px 0px', backgroundColor: '#fdb539', padding: '30px', color: '#333333', fontSize: '17px', lineHeight: '23px', fontWeight: 300 }}>ZIP Code: <strong>{props.zipCode}</strong>&nbsp;&nbsp;[
                <a
                  onClick={props.prevPage}
                  style={{ color: '#333333', textDecoration: 'none', textShadow: 'none', cursor: "pointer" }}>
                  change
                </a>
                ]</div>
            </div>
            <div style={{ display: 'inline-block', width: '54%', maxWidth: '700px', backgroundColor: '#005dab', height: '520px', verticalAlign: 'top' }}>
              <div style={{ padding: '40px 40px 30px 40px' }}>
                <div className="monty" style={{ color: '#ffffff', fontSize: '30px', lineHeight: '33px', fontWeight: 400 }}>Get a FREE* Small Business Security System from your ADT Authorized Dealer <span style={{ whiteSpace: 'nowrap' }}>Home Security Alarm Experts</span></div>
                <div className="monty" style={{ marginTop: '20px', color: '#ffffff', fontSize: '21px', lineHeight: '27px', fontWeight: 300 }}>Just complete and submit your information below.</div>
                <div style={{ marginTop: '20px' }}>
                  <form>
                    <div style={{ width: '225px', display: 'inline-block', marginRight: '20px' }}>
                      <input
                        type="text"
                        style={{ width: "90%" }}
                        name="first"
                        placeholder="*First Name"
                        data-corners="false"
                        value={props.firstName}
                        onChange={(e) => props.setFirstName(e.target.value)}
                      />
                      <div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important' }} /></div><div style={{ width: '225px', display: 'inline-block' }}>
                      <input
                        type="text"
                        style={{ width: "90%" }}
                        name="last"
                        placeholder="*Last Name"
                        data-corners="false"
                        value={props.lastName}
                        onChange={(e) => props.setLastName(e.target.value)}
                      />
                    </div><br />
                    <div style={{ width: '470px', marginTop: '15px' }}>
                      <input
                        type="text"
                        style={{ width: "90%" }}
                        name="email"
                        placeholder="*Email"
                        data-corners="false"
                        value={props.email}
                        onChange={(e) => props.setEmail(e.target.value)}
                      />
                    </div>
                    <div style={{ width: '225px', display: 'inline-block', margin: '15px 20px 0px 0px' }}>
                      <input
                        type="text"
                        style={{ width: "90%" }}
                        name="phone"
                        placeholder="*Phone (Numbers Only)"
                        data-corners="false"
                        maxLength={10}
                        value={props.phone}
                        onChange={(e) => props.setPhone(e.target.value)}
                      />
                    </div><div style={{ width: '150px', display: 'inline-block', marginTop: '15px' }}>
                      <button className="greenhover" name="hown" value="no"
                        onClick={props.nextPage}
                        disabled={isDisabled()}
                      >Submit</button>
                    </div><br />
                  </form>
                </div>
                <div className="montylegal" style={{ paddingTop: '15px', color: '#ffffff', fontWeight: 300, letterSpacing: '0.02em', fontSize: '12px', lineHeight: '15px' }}>By clicking the "SUBMIT" button, you acknowledge and provide your consent to receive calls from your ADT Authorized Dealer at the number you submit. Your consent is not a commitment to purchase.</div>
              </div>
            </div>
            {/* legal info */}
            <center>
              <div className="montylegal" style={{ width: '90%', backgroundColor: '#ffffff', textAlign: 'center', padding: '20px', maxWidth: '1200px', borderTop: '1px #cccccc solid', marginTop: '10px' }}>*All equipment is free. $99 installation. 36-month monitoring agreement. Early termination fee applies.<br /><Footer /></div></center>
          </div>
        </div>
      </div></center>

  );
};

export default SmallBiz2Desktop;
