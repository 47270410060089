import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SmallBiz2Mobile from '../components/SmallBiz2/Mobile';
import SmallBiz2Tablet from '../components/SmallBiz2/Tablet';
import SmallBiz2Desktop from '../components/SmallBiz2/Desktop';

const SmallBiz2 = (props) => {
  const navigate = useNavigate();

  const prevPage = () => {
    navigate('/smallbiz');
  }

  const nextPage = () => {
    props.submit(true, 'SmallBiz');
    navigate('/smallbiz/thankyou');
  };

  useEffect(() => {
    if (props.zipCode === '') prevPage();
  })

  return (
    <div style={{ width: "100%", marginBottom: 10 }}>
      <SmallBiz2Mobile prevPage={prevPage} nextPage={nextPage} {...props} />
      <SmallBiz2Tablet prevPage={prevPage} nextPage={nextPage} {...props} />
      <SmallBiz2Desktop prevPage={prevPage} nextPage={nextPage} {...props} />
    </div>
  );
};

export default SmallBiz2;
