import React from 'react';
import Footer from '../Footer/Footer';

const SmallBiz2Mobile = (props) => {

  const isDisabled = () => {
    const firstName = (/.{2,}/).test(props.firstName);
    const lastName = (/.{2,}/).test(props.lastName);
    const email = (/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/).test(props.email);
    const phone = (/^(?:\+1)?[2-9]\d{9}$/).test(props.phone);
    return (!(firstName && lastName && email && phone));
  }

  return (
    <div className="mob">
      <div style={{ width: '100%', background: '#ffffff' }}>
        <div style={{ height: '100%', padding: '5px 0px 5px 0px' }}>
          <center><img src="/art/combologo.png" alt="ADT Offer from Capital One" style={{ height: '45px' }} /></center>
        </div>
        <div align="left">
          <div className="monty" style={{ color: '#000000', fontSize: '22px', lineHeight: '26px', letterSpacing: '0px', fontWeight: 400, padding: '20px 20px 10px 20px', textAlign: 'center' }}>Get a FREE* Small Business Security System from your ADT Authorized Dealer Home Security Alarm Experts</div>
          <div style={{ width: '100%' }}><center><img src="/art/basesmb.jpg" style={{ width: '85%' }} /></center></div>
          <div style={{ backgroundColor: '#005dab' }}>
            <div style={{ padding: '20px 40px 30px 30px' }}>
              <div className="monty" style={{ margin: '10px 0px 20px 0px', color: '#ffffff', fontSize: '17px', lineHeight: '22px', fontWeight: 300 }}>ZIP Code: <strong>{props.zipCode}</strong>&nbsp;&nbsp;[ <a onClick={props.prevPage} style={{ color: '#ffffff', textDecoration: 'none', textShadow: 'none', cursor: "pointer" }}>change</a> ]</div>
              <div className="monty" style={{ color: '#ffffff', fontSize: '17px', lineHeight: '22px', fontWeight: 300 }}>Please complete and submit your information below.</div>
              <div style={{ marginTop: '15px' }} />
              <form>
                <div style={{ width: '100%' }}>
                  <input
                    type="text"
                    style={{ width: "90%" }}
                    name="first"
                    placeholder="*First Name"
                    data-corners="false"
                    value={props.firstName}
                    onChange={(e) => props.setFirstName(e.target.value)}
                  />
                </div>
                <div style={{ width: '100%', marginTop: '10px' }}>
                  <input
                    type="text"
                    style={{ width: "90%" }}
                    name="last"
                    placeholder="*Last Name"
                    data-corners="false"
                    value={props.lastName}
                    onChange={(e) => props.setLastName(e.target.value)}
                  />
                </div>
                <div style={{ width: '100%', marginTop: '10px' }}>
                  <input
                    type="text"
                    style={{ width: "90%" }}
                    name="email"
                    placeholder="*Email"
                    data-corners="false"
                    value={props.email}
                    onChange={(e) => props.setEmail(e.target.value)}
                  />
                </div>
                <div style={{ width: '100%', marginTop: '10px' }}>
                  <input
                    type="text"
                    style={{ width: "90%" }}
                    name="phone"
                    placeholder="*Phone (Numbers Only)"
                    data-corners="false"
                    maxLength={10}
                    value={props.phone}
                    onChange={(e) => props.setPhone(e.target.value)}
                  />
                </div>
                <div style={{ width: '150px', marginTop: '10px' }}>
                  <button className="greenhover" name="hown" value="no"
                    onClick={props.nextPage}
                    disabled={isDisabled()}
                  >Submit</button>
                </div><br />
              </form>
              <div className="montylegal" style={{ paddingTop: '5px', color: '#ffffff', fontWeight: 300, letterSpacing: '0.02em', fontSize: '12px', lineHeight: '15px' }}>By clicking the "SUBMIT" button, you acknowledge and provide your consent to receive calls from your ADT Authorized Dealer at the number you submit. Your consent is not a commitment to purchase.</div>
            </div>
          </div>
          {/* legal info */}
          <div className="montylegal" style={{ marginTop: '20px', padding: '10px 30px 20px 30px', textAlign: 'center' }}>*All equipment is free. $99 installation. 36-month agreement. Early termination fee applies. ADT Small Business Security System includes 1 Command Control Panel, 3 door/window sensors, 1 motion sensor, 3 indoor cameras, battery backup, cellular connection, mobile application, window stickers and outdoor sign.<br />
            <Footer /> </div>
        </div>
      </div>
    </div>

  );
};

export default SmallBiz2Mobile;
