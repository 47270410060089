import React from 'react';

const MainIntro = () => {

  return (
    <center>
      <div style={{ width: "100%", maxWidth: "1150px", marginBottom: "10px" }}>
        {/* mob flag */}
        <div
          className="mob"
          style={{
            backgroundColor: "#ffffff",
            textAlign: "left",
            padding: "0px 0px 0px 0px"
          }}
        >
          <div
            style={{
              float: "left",
              width: "60%",
              height: "60px",
              margin: "0px 5px 0px 0px"
            }}
            align="left"
          >
            <div
              style={{
                display: "inline-block",
                padding: "15px 10px 0px 10px",
                height: "50px",
                verticalAlign: "middle"
              }}
            >
              <img
                src="/art/authdlrlogo.jpg"
                alt="ADT Authorized Dealer"
                align="left"
                style={{ height: "35px" }}
              />
            </div>
          </div>
          {/* <div style={{ float: "left", width: "30%" }} align="right">
            <div
              className="monty"
              style={{ fontSize: "12px", fontWeight: 300, margin: "15px 0px 0px 0px" }}
            >
              <a
                href="lplangexe.php"
                style={{ textDecoration: "none", color: "#999999" }}
              >
                espanõl
              </a>
            </div>
          </div> */}
          <div style={{ clear: "both" }} />
          <div style={{ clear: "both" }} />
        </div>
        {/* tab flag */}
        <div
          className="tab"
          style={{
            backgroundColor: "#ffffff",
            textAlign: "left",
            padding: "10px 20px 0px 20px"
          }}
        >
          <div
            style={{ float: "left", width: "50%", marginTop: "-4px" }}
            align="left"
          >
            &nbsp;{" "}
          </div>
          <div style={{ float: "right", width: "50%", height: "90px" }} align="right">
            <div
              style={{
                display: "inline-block",
                padding: "22px 10px 0px 10px",
                height: "90px",
                verticalAlign: "middle"
              }}
            >
              <img
                src="/art/authdlrlogo.jpg"
                alt="ADT Authorized Dealer"
                align="left"
                style={{ width: "100%", minWidth: "70px", maxWidth: "110px" }}
              />
            </div>
          </div>
          <div style={{ clear: "both" }} />
        </div>
        {/* pc flag */}
        <div
          className="pc"
          style={{
            backgroundColor: "#ffffff",
            textAlign: "left",
            padding: "10px 20px 0px 20px"
          }}
        >
          <div
            style={{ float: "left", width: "50%", marginTop: "-4px" }}
            align="left"
          >
            &nbsp;{" "}
          </div>
          <div
            style={{ float: "left", width: "50%", marginTop: "0px", height: "90px" }}
            align="right"
          >
            <div
              style={{
                display: "inline-block",
                padding: "22px 10px 0px 10px",
                height: "90px",
                verticalAlign: "middle"
              }}
            >
              <img
                src="/art/authdlrlogo.jpg"
                alt="ADT Authorized Dealer"
                align="left"
                style={{ width: "100%", minWidth: "70px", maxWidth: "110px" }}
              />
            </div>
          </div>
          <div style={{ clear: "both" }} />
        </div>
        {/* end flags */}
      </div>
      {/* top image */}
      <div
        className="pc"
        style={{
          width: "100%",
          backgroundImage: "url(art/mainfamrev.jpg)",
          backgroundPosition: "bottom -15vw left",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "33vw",
          maxHeight: "750px",
          padding: "40px 0px 0px 0px"
        }}
        align="right"
      >
        {/* <center>
          <div style={{ width: "100%", maxWidth: "1200px" }} align="right">
            <div
              style={{
                width: "350px",
                backgroundColor: "#1c8fdb",
                padding: "25px",
                textAlign: "left",
                marginRight: "40px"
              }}
            >
              <div
                className="montyhead"
                style={{
                  fontSize: "27px",
                  lineHeight: "25px",
                  fontWeight: 600,
                  color: "#000000",
                  letterSpacing: "0.03em"
                }}
              >
                Enter your promo code
              </div>
              <div
                className="montyhead"
                style={{
                  marginTop: "12px",
                  fontSize: "17px",
                  lineHeight: "21px",
                  fontWeight: 300,
                  letterSpacing: "0.03em"
                }}
              >
              </div>
              <form
                id="pcforma"
                name="regfix"
                method="post"
                action="accessexe.php"
                autoComplete="off"
              >
                <div className="ui-grid-a" style={{ marginTop: "10px" }}>
                  <div
                    className="ui-block-a monty2"
                    style={{ width: "30%", paddingTop: "15px" }}
                  >
                    Promo Code
                  </div>
                  <div className="ui-block-b monty2" style={{ width: "50%" }}>
                    <input
                      id="pcode"
                      type="text"
                      name="pcode"
                      placeholder="Promo Code"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="ui-grid-a" style={{ marginTop: "5px" }}>
                  <div className="ui-block-a monty2" style={{ width: "30%" }}>
                    &nbsp;
                  </div>
                  <div className="ui-block-b monty2" style={{ width: "50%" }}>
                    <input type="hidden" name="zcode" defaultValue={"000000"} />
                    <button className="divhoverblue" type="submit">
                      <div
                        className="montybutt2"
                        style={{ fontSize: "12px", fontWeight: 600 }}
                      >
                        NEXT
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </center> */}
      </div>
      {/* flag image tab */}
      <div
        className="tab"
        style={{
          width: "100%",
          backgroundImage: "url(art/mainfamrev.jpg)",
          backgroundPosition: "center left -4vw",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "42vw",
          maxHeight: "320px",
          padding: "25px"
        }}
        align="left"
      >
        {/* <center>
          <div style={{ width: "95%", paddingRight: "20px" }} align="right">
            <div
              style={{
                width: "320px",
                backgroundColor: "#f84f40",
                padding: "25px",
                textAlign: "left",
                marginRight: "20px"
              }}
            >
              <div
                className="montyhead"
                style={{
                  fontSize: "24px",
                  lineHeight: "21px",
                  fontWeight: 600,
                  color: "#000000",
                  letterSpacing: "0.03em"
                }}
              >
                Enter your promo code
              </div>
              <div
                className="montyhead"
                style={{
                  marginTop: "12px",
                  fontSize: "15px",
                  lineHeight: "20px",
                  fontWeight: 300,
                  letterSpacing: "0.03em"
                }}
              >
              </div>
              <form
                id="pcforma"
                name="regfix"
                method="post"
                action="accessexe.php"
                autoComplete="off"
              >
                <div className="ui-grid-a" style={{ marginTop: "10px" }}>
                  <div
                    className="ui-block-a monty2"
                    style={{ width: "30%", paddingTop: "15px" }}
                  >
                    Promo Code
                  </div>
                  <div className="ui-block-b monty2" style={{ width: "50%" }}>
                    <input
                      id="pcode"
                      type="text"
                      name="pcode"
                      placeholder="Promo Code"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="ui-grid-a" style={{ marginTop: "5px" }}>
                  <div className="ui-block-a monty2" style={{ width: "30%" }}>
                    &nbsp;
                  </div>
                  <div className="ui-block-b monty2" style={{ width: "50%" }}>
                    <input type="hidden" name="zcode" defaultValue={"000000"} />
                    <button className="divhoverblue" type="submit">
                      <div
                        className="montybutt2"
                        style={{ fontSize: "12px", fontWeight: 600 }}
                      >
                        NEXT
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </center> */}
      </div>
      {/* image flag mob */}
      <div className="mob" style={{ width: "100%", marginTop: "-10px" }}>
        <img
          src="/art/mainimgmob.jpg"
          alt="Protect What You Love"
          style={{ width: "100%" }}
        />
      </div>
      <div
        data-role="page"
        style={{
          width: "100%",
          maxWidth: "1120px",
          backgroundColor: "#ffffff",
          paddingBottom: "50px"
        }}
      >
        {/* mob flag */}
        <div
          className="mob"
          style={{
            backgroundColor: "#ffffff",
            textAlign: "left",
            padding: "10px 20px 10px 20px"
          }}
        >
          {/* <div align="right" class="monty" style="margin-bottom:20px; font-size:15px; font-weight:300; "><a href="lplangexe.php" style="text-decoration:none; color:#999999; ">espan&otilde;l</a></div> */}{" "}
          <center>
            <h1
              className="montyhead"
              style={{
                width: "100%",
                fontSize: "20px",
                letterSpacing: "-0.01em",
                lineHeight: "22px",
                margin: "0px 0px 10px 0px"
              }}
            >
              When it comes to smart home security, trust&nbsp;the&nbsp;pros.
            </h1>
            {/* <div
              className="monty"
              style={{
                width: "95%",
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "-0.01em"
              }}
            >
              Enter information below for your exclusive offer.
            </div> */}
          </center>
        </div>
        {/* mob entry bar */}
        {/* <div
          className="mob"
          style={{ width: "100%", padding: "20px", backgroundColor: "#1c8fdb" }}
          align="center"
        >
          <div align="left" style={{ width: "80%" }}>
            <form
              id="mobform"
              name="regfix"
              method="post"
              action="accessexe.php"
              autoComplete="off"
            >
              <div style={{ width: "50%" }} align="left">
                <input
                  type="text"
                  name="pcode"
                  placeholder="Offer Code"
                  autoComplete="off"
                  style={{ width: "80%" }}
                />
              </div>
              <div
                style={{ display: "inline-block", width: "35%", marginTop: "10px" }}
                align="left"
              >
                <input type="hidden" name="zcode" defaultValue={"000000"} />
                <button className="divhoverblue" type="submit">
                  <div
                    className="montybutt2"
                    style={{
                      fontWeight: 700,
                      fontSize: "13px",
                      letterSpacing: "0.05em"
                    }}
                  >
                    NEXT
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div> */}
        {/* <div className="mob">
          <center>&nbsp;</center>
        </div> */}
        {/* tab flag */}
        <div
          className="tab"
          style={{
            backgroundColor: "#ffffff",
            textAlign: "left",
            padding: "20px 20px 10px 20px"
          }}
        >
          {/* <div
            align="right"
            className="monty"
            style={{ marginBottom: "20px", fontSize: "15px", fontWeight: 300 }}
          >
            <a
              href="lplangexe.php"
              style={{ textDecoration: "none", color: "#999999" }}
            >
              espanõl
            </a>
          </div> */}
          <center>
            <h1
              className="montyhead"
              style={{
                width: "100%",
                fontSize: "33px",
                lineHeight: "38px",
                margin: "0px 0px 15px 0px"
              }}
            >
              When it comes to smart home security, trust the pros.
            </h1>
            <div
              style={{ width: "70% min-width:650px", maxWidth: "820px" }}
              align="left"
            >
              <div
                className="montyhead"
                style={{
                  fontSize: "40px",
                  borderBottom: "2px #1c8fdb solid",
                  paddingBottom: "10px",
                  marginBottom: "40px"
                }}
              >
                The ADT monitoring advantage
              </div>
              <div style={{ float: "left", width: "31%" }} align="left">
                <img
                  src="/art/iconstop.jpg"
                  alt="Years of Experience"
                  style={{ display: "block", marginBottom: "20px" }}
                />
                <div
                  className="monty"
                  style={{ fontSize: "24px", letterSpacing: "0.02em", fontWeight: 600 }}
                >
                  145 years of experience
                </div>
                <div
                  className="monty"
                  style={{
                    paddingTop: "20px",
                    fontSize: "17px",
                    letterSpacing: "0.02em",
                    fontWeight: 300
                  }}
                >
                  ADT has your back. We've been helping to protect homes longer than
                  any other company in the business.
                </div>
              </div>
              <div style={{ float: "left", width: "50px" }} align="left">
                &nbsp;
              </div>
              <div style={{ float: "left", width: "30%" }} align="left">
                <img
                  src="/art/iconpc.jpg"
                  alt="Professional Monitoring"
                  style={{ display: "block", marginBottom: "20px" }}
                />
                <div
                  className="monty"
                  style={{ fontSize: "24px", letterSpacing: "0.02em", fontWeight: 600 }}
                >
                  24/7 professional monitoring
                </div>
                <div
                  className="monty"
                  style={{
                    paddingTop: "20px",
                    fontSize: "17px",
                    letterSpacing: "0.02em",
                    fontWeight: 300
                  }}
                >
                  Rest easy knowing that ADT is always ready to help in an
                  emergency.
                </div>
              </div>
              <div style={{ float: "left", width: "50px" }} align="left">
                &nbsp;
              </div>
              <div style={{ float: "left", width: "24%" }} align="left">
                <img
                  src="/art/iconribbon.jpg"
                  alt="ADT Guarantee"
                  style={{ display: "block", marginBottom: "20px" }}
                />
                <div
                  className="monty"
                  style={{ fontSize: "24px", letterSpacing: "0.02em", fontWeight: 600 }}
                >
                  ADT 6-month money back guarantee
                </div>
                <div
                  className="monty"
                  style={{
                    paddingTop: "20px",
                    fontSize: "17px",
                    letterSpacing: "0.02em",
                    fontWeight: 300
                  }}
                >
                  If ADT can’t resolve your system related issue, ADT will refund
                  your installation and monitoring fees.*
                </div>
                <div
                  className="monty"
                  style={{
                    paddingTop: "18px",
                    fontSize: "10px",
                    letterSpacing: "0.02em",
                    fontWeight: 300
                  }}
                >
                  Certain restrictions may apply. See terms and conditions below.
                </div>
              </div>
              <div style={{ clear: "both" }} />
            </div>
          </center>
        </div>
        {/* pc */}
        <div
          className="pc"
          style={{
            backgroundColor: "#ffffff",
            textAlign: "left",
            padding: "10px 20px 10px 20px"
          }}
        >
          {/* <div
            align="right"
            className="monty"
            style={{ marginBottom: "20px", fontSize: "15px", fontWeight: 300 }}
          >
            <a
              href="lplangexe.php"
              style={{ textDecoration: "none", color: "#999999" }}
            >
              espanõl
            </a>
          </div> */}
          <center>
            <h1
              className="montyhead"
              style={{
                width: "100%",
                fontSize: "33px",
                lineHeight: "38px",
                margin: "0px 0px 15px 0px"
              }}
            >
              When it comes to smart home security, trust the pros.
            </h1>
            <div
              style={{ width: "70% min-width:650px", maxWidth: "820px" }}
              align="left"
            >
              <div
                className="montyhead"
                style={{
                  fontSize: "40px",
                  borderBottom: "2px #1c8fdb solid",
                  paddingBottom: "10px",
                  marginBottom: "40px"
                }}
              >
                The ADT monitoring advantage
              </div>
              <div style={{ float: "left", width: "31%" }} align="left">
                <img
                  src="/art/iconstop.jpg"
                  alt="Years of Experience"
                  style={{ display: "block", marginBottom: "20px" }}
                />
                <div
                  className="monty"
                  style={{ fontSize: "24px", letterSpacing: "0.02em", fontWeight: 600 }}
                >
                  145 years of experience
                </div>
                <div
                  className="monty"
                  style={{
                    paddingTop: "20px",
                    fontSize: "17px",
                    letterSpacing: "0.02em",
                    fontWeight: 300
                  }}
                >
                  ADT has your back. We've been helping to protect homes longer than
                  any other company in the business.
                </div>
              </div>
              <div style={{ float: "left", width: "50px" }} align="left">
                &nbsp;
              </div>
              <div style={{ float: "left", width: "30%" }} align="left">
                <img
                  src="/art/iconpc.jpg"
                  alt="Professional Monitoring"
                  style={{ display: "block", marginBottom: "20px" }}
                />
                <div
                  className="monty"
                  style={{ fontSize: "24px", letterSpacing: "0.02em", fontWeight: 600 }}
                >
                  24/7 professional monitoring
                </div>
                <div
                  className="monty"
                  style={{
                    paddingTop: "20px",
                    fontSize: "17px",
                    letterSpacing: "0.02em",
                    fontWeight: 300
                  }}
                >
                  Rest easy knowing that ADT is always ready to help in an
                  emergency.
                </div>
              </div>
              <div style={{ float: "left", width: "50px" }} align="left">
                &nbsp;
              </div>
              <div style={{ float: "left", width: "24%" }} align="left">
                <img
                  src="/art/iconribbon.jpg"
                  alt="ADT Guarantee"
                  style={{ display: "block", marginBottom: "20px" }}
                />
                <div
                  className="monty"
                  style={{ fontSize: "24px", letterSpacing: "0.02em", fontWeight: 600 }}
                >
                  ADT 6-month money back guarantee
                </div>
                <div
                  className="monty"
                  style={{
                    paddingTop: "20px",
                    fontSize: "17px",
                    letterSpacing: "0.02em",
                    fontWeight: 300
                  }}
                >
                  If ADT can’t resolve your system related issue, ADT will refund
                  your installation and monitoring fees.*
                </div>
                <div
                  className="monty"
                  style={{
                    paddingTop: "18px",
                    fontSize: "10px",
                    letterSpacing: "0.02em",
                    fontWeight: 300
                  }}
                >
                  Certain restrictions may apply. See terms and conditions below.
                </div>
              </div>
              <div style={{ clear: "both" }} />
            </div>
          </center>
        </div>
      </div>
      <div
        className="montylegal"
        style={{
          backgroundColor: "#ffffff",
          textAlign: "left",
          padding: "20px 20px 50px 20px",
          maxWidth: "1000px",
          borderTop: "1px #cccccc solid",
          marginTop: "20px"
        }}
      >
        *6-Month ADT Money-Back Guarantee: Money back guarantee only applies after
        ADT has made attempts to resolve a system related issue and has not been
        able to resolve that issue within the first 6 months of your contract.
        Equipment must be fully removed before a refund will be processed.
        Conditions preventing normal system operation cannot be caused by the
        customer.
      </div>
    </center>


  );
};

export default MainIntro;
