import React from 'react';
import Footer from '../Footer/Footer';

const SmallBiz1Mobile = (props) => {

  return (
    <div className="mob">
      <div style={{ width: '100%', background: '#ffffff' }}>
        <div style={{ height: '100%', padding: '5px 0px 5px 0px' }}>
          <center><img src="/art/combologo.png" alt="ADT Offer from Capital One" style={{ height: '45px' }} /></center>
        </div>
        <div align="left" style={{ backgroundColor: '#ffffff' }}>
          <div style={{ padding: '30px 40px 30px 30px', backgroundColor: '#005dab' }}>
            <div className="monty" style={{ color: '#ffffff', fontSize: '24px', lineHeight: '25px', fontWeight: 600 }}>Welcome Capital One<span style={{ fontWeight: 300, fontSize: '27px' }}>®</span> Business Deals<span style={{ fontWeight: 300, fontSize: '27px' }}>®</span> Member!</div>
            <div className="monty" style={{ marginTop: '15px', color: '#ffffff', fontSize: '18px', lineHeight: '24px', fontWeight: 300 }}>Protect your business with a FREE* state-of-the-art commercial security system plus 3 FREE* Indoor Cameras ($1,600 value) and get 24/7&nbsp;monitoring for just $57.99 per month.</div>
            <div className="monty" style={{ marginTop: '6px', color: '#86a3d7', fontSize: '12px', lineHeight: '15px', fontWeight: 300 }}>*$99 installation fee and 36-month agreement required. Early termination fee applies.</div>
            <div style={{ marginTop: '20px' }} />
            <form onSubmit={props.nextPage}>
              <input
                type="tel"
                name="zip"
                maxLength={5}
                placeholder="ZIP Code"
                data-corners="false"
                value={props.zipCode}
                onChange={(e) => props.setZipCode(e.target.value)}
                style={{ width: 100 }}
              />
              <button
                className="greenhover"
                disabled={!(/[0-9]{5}/).test(props.zipCode)}
                style={{ marginLeft: 10, fontSize: 10 }}
              >
                Submit
              </button>
            </form>
            <div className="monty" style={{ marginTop: '20px', color: '#ffffff', fontSize: '12px', lineHeight: '30px', fontWeight: 300, letterSpacing: '0.02em' }}>
              <img src="/art/check.png" alt="Check it out" style={{ margin: '0px 8px -5px 0px' }} />Base Security System ($850 value)<br />
              <img src="/art/check.png" alt="Check it out" style={{ margin: '0px 8px -5px 0px' }} />3 Nightvision, Motion-Activated Indoor Cameras ($750 value)<br />
              <img src="/art/check.png" alt="Check it out" style={{ margin: '0px 8px -5px 0px' }} />24/7 Professional Monitoring by the world's #1 provider<br />
              <img src="/art/check.png" alt="Check it out" style={{ margin: '0px 8px -5px 0px' }} />Cameras, Sensors, Remote Activation and more
            </div>
          </div>
        </div>
        <div className="montylegal" style={{ marginTop: '10px', padding: '10px 30px 20px 30px', borderTop: '1px #cccccc solid', textAlign: 'center' }}>
          <Footer />
        </div>
      </div>
    </div>

  );
};

export default SmallBiz1Mobile;
