import React from 'react';
import { useNavigate } from 'react-router-dom';
import Residential1Mobile from '../components/Residential1/Mobile';
import Residential1Tablet from '../components/Residential1/Tablet';
import Residential1Desktop from '../components/Residential1/Desktop';

const Residential1 = (props) => {
  const navigate = useNavigate();

  const nextPage = () => {
    navigate('/residential/upgrades');
  };

  return (
    <div style={{ width: "100%", marginBottom: 10 }}>
      <Residential1Mobile nextPage={nextPage} {...props} />
      <Residential1Tablet nextPage={nextPage} {...props} />
      <Residential1Desktop nextPage={nextPage} {...props} />
    </div>

  );
};

export default Residential1;
