import React from 'react';
import Footer from '../Footer/Footer';

const SmallBiz1Tablet = (props) => {

  return (
    <div className="tab">
      {/* flag */}
      <div style={{ width: '100%', height: '90px', background: '#ffffff' }}>
        <div style={{ height: '100%', paddingBottom: '0px' }}>
          <div style={{ float: 'left', width: '50%' }} align="left"><img src="/art/authdlrlogo.jpg" alt="ADT Authorized Dealer" style={{ height: '45px', padding: '23px 0px 0px 53px' }} /></div>
          <div style={{ float: 'left', width: '50%' }} align="right"><img src="/art/cospringlogo.jpg" alt="Capital One Business Deals" style={{ width: '250px', padding: '23px 90px 0px 0px' }} /></div>
          <div style={{ clear: 'both' }} />
        </div>
        <div align="left" style={{ backgroundColor: '#ffffff' }}>
          <div style={{ float: 'left', width: '40%' }}><img src="/art/smbizimg.jpg" alt="Small Business Security Solutions" style={{ width: '100%' }} /></div>
          <div style={{ float: 'left', width: '60%' }}>
            <div style={{ padding: '20px 40px 20px 30px' }}>
              <div className="monty" style={{ color: '#000000', fontSize: '4.2vw', lineHeight: '4.5vw font-weight:600' }}>Welcome Capital One<span style={{ fontWeight: 300, fontSize: '21px' }}>®</span> Business Deals<span style={{ fontWeight: 300, fontSize: '21px' }}>®</span> Member!</div>
              <div className="monty" style={{ marginTop: '30px', color: '#000000', fontSize: '3.2vw', lineHeight: '3.6vw', fontWeight: 300 }}>Protect your business with a FREE* state-of-the-art commercial security system plus 3 FREE* Indoor Cameras ($1,600 value) and get 24/7&nbsp;monitoring for just $57.99 per month.</div>
              <div className="monty" style={{ width: '60%', minWidth: '300px', marginTop: '20px', color: '#999999', fontSize: '12px', lineHeight: '15px', fontWeight: 300, letterSpacing: '0.01em' }}>*$99 installation fee and 36-month agreement required. Early termination fee applies.</div>
            </div>
          </div>
          <div style={{ clear: 'both' }} />
          <div style={{ width: '100%', backgroundColor: '#005dab', marginTop: '-4px', padding: '40px 50px 50px 50px' }}>
            <div className="monty" style={{ padding: '0px 0px 30px 0px', color: '#ffffff', fontSize: '18px', lineHeight: '22px', fontWeight: 300 }}>To begin, please enter your Zip Code and click Submit.</div>
            <form onSubmit={props.nextPage}>
              <input
                type="tel"
                name="zip"
                maxLength={5}
                placeholder="ZIP Code"
                data-corners="false"
                value={props.zipCode}
                onChange={(e) => props.setZipCode(e.target.value)}
                style={{ width: 150 }}
              />
              <button className="greenhover"
                disabled={!(/[0-9]{5}/).test(props.zipCode)}
                style={{ marginLeft: 16 }}>
                Submit
              </button>
            </form>
            <div className="monty" style={{ marginTop: '30px', color: '#ffffff', fontSize: '17px', lineHeight: '32px', fontWeight: 300, letterSpacing: '0.02em' }}>
              <img src="/art/check.png" alt="Check it out" style={{ margin: '0px 8px -5px 0px' }} />Base Security System ($850 value)<br />
              <img src="/art/check.png" alt="Check it out" style={{ margin: '0px 8px -5px 0px' }} />3 Nightvision, Motion-Activated Indoor Cameras ($750 value)<br />
              <img src="/art/check.png" alt="Check it out" style={{ margin: '0px 8px -5px 0px' }} />24/7 Professional Monitoring by the world's #1 provider<br />
              <img src="/art/check.png" alt="Check it out" style={{ margin: '0px 8px -5px 0px' }} />Cameras, Sensors, Remote Activation and more
            </div>
          </div>
          {/* legal info */}
          <div className="montylegal" style={{ backgroundColor: '#ffffff', textAlign: 'center', padding: '20px 20px 20px 20px', width: '100%', maxWidth: '1000px', borderTop: '1px #cccccc solid', marginTop: '20px' }}>
            <Footer />
          </div>
        </div>
      </div>
    </div>

  );
};

export default SmallBiz1Tablet;
