import React from 'react';
import Footer from '../Footer/Footer';

const Residential2Mobile = (props) => {

  return (
    <div className="mob">
      {/* flag */}
      <div style={{ width: "100%", background: "#ffffff" }}>
        <div style={{ height: "100%", padding: "5px 0px 5px 0px" }}>
          <center>
            <img
              src="/art/combologo.png"
              alt="ADT Offer from Capital One"
              style={{ height: "45px" }}
            />
          </center>
        </div>
        <div align="left">
          <center>
            <div
              className="monty"
              style={{
                color: "#000000",
                fontSize: "18px",
                lineHeight: "22px",
                letterSpacing: "-0.01em",
                fontWeight: 400,
                padding: "0px 30px 0px 30px"
              }}
            >
              FREE* Smart Home Security System PLUS Bonus Equipment from your ADT
              Authorized Dealer - <strong>Home Security Alarm Experts</strong>.
            </div>
            <div style={{ width: "100%" }}>
              <img
                src="/art/basebase.jpg"
                style={{ width: "63%" }}
                alt="Base Security System"
              />
            </div>
          </center>
          <div
            className="monty"
            style={{
              width: "100%",
              margin: "10px 0px 0px 0px",
              backgroundColor: "#fdb539",
              padding: "15px 30px 15px 5px",
              color: "#333333",
              fontSize: "15px",
              lineHeight: "16px",
              fontWeight: 300,
              textAlign: "center"
            }}
          >
            ZIP Code: <strong>{props.zipCode}</strong>&nbsp;&nbsp;[{" "}
            <a
              onClick={props.prevPage}
              style={{
                color: "#333333",
                textDecoration: "none",
                textShadow: "none",
                cursor: "pointer"
              }}
            >
              change{" "}
            </a>{" "}
            ]
          </div>
          <div style={{ width: "100%", backgroundColor: "#005dab" }}>
            <div style={{ padding: "20px" }}>
              <form onSubmit={props.nextPage}>
                <center>
                  <div
                    className="monty"
                    style={{ color: "#ffffff", fontSize: "21px", lineHeight: "24px" }}
                  >
                    <strong>Choose Bonus Equipment</strong>
                  </div>
                  <div
                    className="monty"
                    style={{
                      color: "#ffffff",
                      fontSize: "15px",
                      lineHeight: "24px",
                      fontWeight: 400
                    }}
                  >
                    1 FREE* Video Device (click to pick)
                  </div>
                  <div className="radios" style={{ margin: "2px 0px 10px 0px" }}>
                    <div style={{ display: "inline-block", width: "31%" }}>
                      <input
                        type="radio"
                        name="video"
                        checked={props.videoDevice === 1}
                        onChange={() => props.setVideoDevice(1)}
                        id="r1"
                      />
                      <label className="radio" htmlFor="r1">
                        <img
                          src="/art/vdb.png"
                          style={{ marginTop: "10px", height: "40px" }}
                        />
                        <br />
                        Video
                        <br />
                        Doorbell
                      </label>
                    </div>
                    <div style={{ display: "inline-block", width: "31%" }}>
                      <input type="radio" name="video"
                        checked={props.videoDevice === 2}
                        onChange={() => props.setVideoDevice(2)}
                        id="r2" />
                      <label className="radio" htmlFor="r2">
                        <img
                          src="/art/blkcam.png"
                          style={{ marginTop: "10px", height: "40px" }}
                        />
                        <br />
                        HD Indoor
                        <br />
                        Camera
                      </label>
                    </div>
                    <div style={{ display: "inline-block", width: "31%" }}>
                      <input type="radio" name="video"
                        checked={props.videoDevice === 3}
                        onChange={() => props.setVideoDevice(3)}
                        id="r3" />
                      <label className="radio" htmlFor="r3">
                        <img
                          src="/art/odc.png"
                          style={{ marginTop: "10px", height: "40px" }}
                        />
                        <br />
                        HD Outdoor
                        <br />
                        Camera
                      </label>
                    </div>
                  </div>
                  <div
                    className="monty"
                    style={{
                      color: "#ffffff",
                      fontSize: "15px",
                      lineHeight: "24px",
                      fontWeight: 400
                    }}
                  >
                    1 FREE* Smart Device (click to pick)
                  </div>
                  <div className="radios" style={{ margin: "2px 0px 10px 0px" }}>
                    <div style={{ display: "inline-block", width: "31%" }}>
                      <input
                        type="radio"
                        name="smart"
                        checked={props.smartDevice === 1}
                        onChange={() => props.setSmartDevice(1)}
                        id="s1"
                      />
                      <label className="radio" htmlFor="s1">
                        <img
                          src="/art/tmo.png"
                          style={{ marginTop: "10px", height: "40px" }}
                        />
                        <br />
                        Smart
                        <br />
                        Thermostat
                      </label>
                    </div>
                    <div style={{ display: "inline-block", width: "31%" }}>
                      <input
                        type="radio"
                        name="smart"
                        checked={props.smartDevice === 2}
                        onChange={() => props.setSmartDevice(2)}
                        id="s2" />
                      <label className="radio" htmlFor="s2">
                        <img
                          src="/art/sdl.png"
                          style={{ marginTop: "10px", height: "40px" }}
                        />
                        <br />
                        Smart
                        <br />
                        Door Lock
                      </label>
                    </div>
                    <div style={{ display: "inline-block", width: "31%" }}>
                      <input
                        type="radio"
                        name="smart"
                        checked={props.smartDevice === 3}
                        onChange={() => props.setSmartDevice(3)}
                        id="s3" />
                      <label className="radio" htmlFor="s3">
                        <img
                          src="/art/gar.png"
                          style={{ marginTop: "10px", height: "40px" }}
                        />
                        <br />
                        Smart Garage
                        <br />
                        Control
                      </label>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "150px",
                      display: "inline-block",
                      margin: "10px 0px 0px 10px"
                    }}
                  >
                    <button className="greenhover">Next</button>
                  </div>
                  <br />
                </center>
              </form>
            </div>
          </div>
          <div
            className="montylegal"
            style={{
              padding: "10px 25px 10px 25px",
              textAlign: "center",
              lineHeight: "15px"
            }}
          >
            *All equipment is free. $99 installation and 36-month monitoring
            agreement required (24-month in CA). Early termination fee applies.
            ADT Smart Home Security System includes 1 Command Control Panel, 3
            door/window sensors, 1 motion sensor, battery backup, cellular
            communication, mobile application, window stickers and outdoor sign,
            PLUS 1 video device and 1 smart device.
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Residential2Mobile;
