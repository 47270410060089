import React from 'react';
import Footer from '../Footer/Footer';

const Residential4Mobile = (props) => {

  return (
    <div className="mob">
      {/* flag */}
      <div style={{ width: "100%", background: "#ffffff" }}>
        <div style={{ height: "100%", padding: "5px 0px 5px 0px" }}>
          <center>
            <img
              src="/art/combologo.png"
              alt="ADT Offer from Capital One"
              style={{ height: "45px" }}
            />
          </center>
        </div>
        <div align="left">
          <div
            className="monty"
            style={{
              color: "#000000",
              fontSize: "22px",
              lineHeight: "28px",
              letterSpacing: "0px",
              fontWeight: 400,
              padding: "30px"
            }}
          >
            Thank you for your interest in an ADT Smart Home Security System!
          </div>
          <div
            className="monty"
            style={{
              color: "#000000",
              fontSize: "16px",
              lineHeight: "20px",
              letterSpacing: "0px",
              fontWeight: 40,
              padding: "0px 30px"
            }}
          >
            Please watch your email for offer confirmation. A representative from
            Home Security Alarm Experts will reach out to you shortly.
          </div>
          <br />
          <br />
          <center>
            <div style={{ display: "inline-block", marginTop: "10px" }}>
              <button onClick={props.reset} className="greenhover">Reset</button>
            </div>
          </center>
          <div
            className="montylegal"
            style={{
              margin: "20px 30px 20px 30px",
              paddingTop: "20px",
              borderTop: "1px #cccccc solid"
            }}
          >
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Residential4Mobile;
