import React from 'react';

const Footer = () => {

  return (
    <span>
      ©{new Date().getFullYear()} ADT, Inc. All rights reserved. Capital One Business Deals logo
      used with permission.
    </span>
  );
};

export default Footer;
