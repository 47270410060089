import React from 'react';
import Footer from '../Footer/Footer';

const SmallBiz3Mobile = (props) => {

  return (
    <div className="mob">
      <div style={{ width: '100%', background: '#ffffff' }}>
        <div style={{ height: '100%', padding: '5px 0px 5px 0px' }}>
          <center><img src="/art/combologo.png" alt="ADT Offer from Capital One" style={{ height: '45px' }} /></center>
        </div>
        <div align="left">
          <div className="monty" style={{ width: '80%', color: '#000000', fontSize: '22px', lineHeight: '28px', letterSpacing: '0px', fontWeight: 600, padding: '30px', textAlign: 'center' }}>Thank you for your interest in an ADT Small Business Security System!</div>
          <div className="monty" style={{ color: '#000000', fontSize: '14px', lineHeight: '20px', letterSpacing: '0px', fontWeight: 40, padding: '0px 30px', textAlign: 'center' }}>Please watch your email for offer confirmation. A representative from Home Security Alarm Experts will reach out to you shortly.<br /><br />
            <a onClick={props.reset}><div style={{ display: 'inline-block', marginTop: '15px' }}><button className="greenhover">Reset</button></div></a>
          </div>
          <div className="montylegal" style={{ margin: '20px 30px 20px 30px', paddingTop: '20px', borderTop: '1px #cccccc solid' }}><Footer /></div>
        </div>
      </div>
    </div>

  );
};

export default SmallBiz3Mobile;
