import React from 'react';
import { useNavigate } from 'react-router-dom';
import SmallBiz3Mobile from '../components/SmallBiz3/Mobile';
import SmallBiz3Tablet from '../components/SmallBiz3/Tablet';
import SmallBiz3Desktop from '../components/SmallBiz3/Desktop';

const SmallBiz3 = (props) => {
  const navigate = useNavigate();

  const reset = () => {
    props.resetState();
    navigate('/smallbiz');
  }

  return (
    <div style={{ width: "100%", marginBottom: 10 }}>
      <SmallBiz3Mobile reset={reset} {...props} />
      <SmallBiz3Tablet reset={reset} {...props} />
      <SmallBiz3Desktop reset={reset} {...props} />
    </div>
  );
};

export default SmallBiz3;
