import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Residential2Mobile from '../components/Residential2/Mobile';
import Residential2Tablet from '../components/Residential2/Tablet';
import Residential2Desktop from '../components/Residential2/Desktop';

const Residential2 = (props) => {
  const navigate = useNavigate();

  const prevPage = () => {
    navigate('/residential');
  }

  const nextPage = () => {
    navigate('/residential/contact');
  };

  useEffect(() => {
    if (props.zipCode === '') prevPage();
  })

  return (
    <div style={{ width: "100%", marginBottom: "10px" }}>
      <Residential2Mobile prevPage={prevPage} nextPage={nextPage} {...props} />
      <Residential2Tablet prevPage={prevPage} nextPage={nextPage} {...props} />
      <Residential2Desktop prevPage={prevPage} nextPage={nextPage} {...props} />
    </div>
  );
};

export default Residential2;
