import React from 'react';
import { useNavigate } from 'react-router-dom';
import Residential5Mobile from '../components/Residential5/Mobile';
import Residential5Tablet from '../components/Residential5/Tablet';
import Residential5Desktop from '../components/Residential5/Desktop';

const Residential5 = (props) => {

  const navigate = useNavigate();

  const reset = () => {
    props.resetState();
    navigate('/residential');
  }

  return (
    <div style={{ width: "100%", marginBottom: 10 }}>
      <Residential5Mobile reset={reset} {...props} />
      <Residential5Tablet reset={reset} {...props} />
      <Residential5Desktop reset={reset} {...props} />
    </div>
  );
};

export default Residential5;
