import React from 'react';
import Footer from '../Footer/Footer';

const SmallBiz3Tablet = (props) => {

  return (
    <div className="tab">
      {/* flag */}
      <div style={{ width: '100%', height: '90px', background: '#ffffff' }}>
        <div style={{ height: '100%', paddingBottom: '0px' }}>
          <div style={{ float: 'left', width: '50%' }} align="left"><img src="/art/authdlrlogo.jpg" alt="ADT Authorized Dealer" style={{ height: '45px', padding: '23px 0px 0px 53px' }} /></div>
          <div style={{ float: 'left', width: '50%' }} align="right"><img src="/art/cospringlogo.jpg" alt="Capital One Business Deals" style={{ width: '250px', padding: '23px 90px 0px 0px' }} /></div>
          <div style={{ clear: 'both' }} />
        </div>
        <div align="left">
          <center>
            <div className="monty" style={{ width: '80%', padding: '0px 20px 0px 20px', color: '#000000', fontSize: '3.7vw', lineHeight: '4.1vw font-weight:400' }}>Thank you for your interest in an ADT Small Business Security System!</div>
            <div className="monty" style={{ width: '75%', padding: '30px 20px 0px 20px', color: '#000000', fontSize: '2.7vw', lineHeight: '3.1vw font-weight:400' }}>Please check your email for confirmation. A representative from Home Security Alarm Experts will reach out to you shortly.</div>
            <a onClick={props.reset}><div style={{ display: 'inline-block', marginTop: '15px' }}><button className="greenhover">Reset</button></div></a>
          </center>
          {/* legal info */}
          <div className="montylegal" style={{ backgroundColor: '#ffffff', textAlign: 'center', padding: '20px 30px 20px 30px', width: '90%', lineHeight: '15px' }}>
            <Footer />
          </div>
        </div>
      </div>
    </div>

  );
};

export default SmallBiz3Tablet;
