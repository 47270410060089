import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Residential3Mobile from '../components/Residential3/Mobile';
import Residential3Tablet from '../components/Residential3/Tablet';
import Residential3Desktop from '../components/Residential3/Desktop';

const Residential3 = (props) => {
  const navigate = useNavigate();

  const prevPageZip = () => {
    navigate('/residential');
  }

  const prevPageUpgrades = () => {
    navigate('/residential/upgrades');
  }

  const nextPageHomeowner = () => {
    props.submit(true, 'Residential');
    navigate('/residential/homeowner');
  };

  const nextPageRenter = () => {
    props.submit(false);
    navigate('/residential/renter');
  };

  useEffect(() => {
    if (props.zipCode === '') prevPageZip();
  });

  return (
    <div style={{ width: "100%", marginBottom: "10px" }}>
      <Residential3Mobile prevPageZip={prevPageZip} prevPageUpgrades={prevPageUpgrades} nextPageHomeowner={nextPageHomeowner} nextPageRenter={nextPageRenter} {...props} />
      <Residential3Tablet prevPageZip={prevPageZip} prevPageUpgrades={prevPageUpgrades} nextPageHomeowner={nextPageHomeowner} nextPageRenter={nextPageRenter} {...props} />
      <Residential3Desktop prevPageZip={prevPageZip} prevPageUpgrades={prevPageUpgrades} nextPageHomeowner={nextPageHomeowner} nextPageRenter={nextPageRenter} {...props} />
    </div>
  );
};

export default Residential3;
