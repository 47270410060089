import React from 'react';
import Footer from '../Footer/Footer';

const Residential2Tablet = (props) => {

  return (
    <div className="tab">
      {/* flag */}
      <div style={{ width: "100%", height: "90px", background: "#ffffff" }}>
        <div style={{ height: "100%", paddingBottom: "0px" }}>
          <div style={{ float: "left", width: "50%" }} align="left">
            <img
              src="/art/74c.jpg"
              alt="ADT Authorized Dealer"
              style={{ height: "45px", padding: "23px 0px 0px 53px" }}
            />
          </div>
          <div style={{ float: "left", width: "50%" }} align="right">
            <img
              src="/art/cospringlogo.jpg"
              alt="Capital One Business Deals"
              style={{ width: "250px", padding: "23px 90px 0px 0px" }}
            />
          </div>
          <div style={{ clear: "both" }} />
        </div>
        <center>
          <div
            className="monty"
            style={{
              width: "85%",
              padding: "0px 20px 20px 20px",
              color: "#000000",
              fontSize: "3.0vw",
              lineHeight: "3.3vw",
              fontWeight: 400
            }}
          >
            Get a FREE* Smart Home Security System from your ADT Authorized Dealer
            -{" "}
            <span style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
              Home Security Alarm Experts
            </span>
            .
          </div>
          <div
            style={{
              display: "inline-block",
              width: "55%",
              verticalAlign: "middle"
            }}
            align="right"
          >
            <img
              src="/art/basebase.jpg"
              alt="Base system"
              style={{ width: "75%", paddingRight: 10 }}
            />
          </div>
          <div
            style={{
              display: "inline-block",
              width: "40%",
              verticalAlign: "middle"
            }}
            align="left"
          >
            <div className="montylegal" style={{ padding: "0px 50px 0px 10px" }}>
              ADT Smart Home Security System includes 1&nbsp;Command Control
              Panel, 3&nbsp;Door/Window Sensors, 1&nbsp;Motion Sensor, Battery
              Backup, Cellular Connection, Mobile Application, Window Stickers and
              Outdoor Signage, PLUS 1&nbsp;Video Device and 1&nbsp;Smart Device.
            </div>
            <div
              className="monty"
              style={{
                width: "80%",
                fontSize: "15px",
                padding: "15px",
                backgroundColor: "#fdb539",
                margin: "15px 0px 15px 0px",
                textAlign: "center"
              }}
            >
              Your ZIP: <strong>{props.zipCode}</strong> [{" "}
              <a
                onClick={props.prevPage}
                style={{ textDecoration: "none", color: "#666666", cursor: "pointer" }}
              >
                Change ZIP
              </a>{" "}
              ]
            </div>
          </div>
        </center>
        <div style={{ width: "100%", backgroundColor: "#005dab", marginTop: "10px" }}>
          <div style={{ padding: "30px 50px 30px 50px" }}>
            <form onSubmit={props.nextPage}>
              <center>
                <div
                  className="monty"
                  style={{
                    color: "#ffffff",
                    fontSize: "27px",
                    lineHeight: "30px",
                    fontWeight: 400,
                    padding: "0px 0px 20px 0px"
                  }}
                >
                  PLUS your choice of FREE Bonus Equipment
                </div>
              </center>
              <div
                className="monty"
                style={{
                  color: "#ffffff",
                  fontSize: "21px",
                  lineHeight: "25px",
                  fontWeight: 300,
                  paddingBottom: "10px"
                }}
              >
                Select 1 Free* Video Device (click to select)
              </div>
              <center>
                <div className="radios" style={{ margin: "2px 0px 10px 0px" }}>
                  <div style={{ display: "inline-block", width: "32%" }}>
                    <input
                      type="radio"
                      name="vidtab"
                      checked={props.videoDevice === 1}
                      onChange={() => props.setVideoDevice(1)}
                      id="vt1"
                    />
                    <label className="radio" htmlFor="vt1">
                      <img
                        src="/art/vdb.png"
                        style={{ marginTop: "10px", height: "60px" }}
                      />
                      <br />
                      Video Doorbell
                    </label>
                  </div>
                  <div style={{ display: "inline-block", width: "32%" }}>
                    <input type="radio" name="vidtab"
                      checked={props.videoDevice === 2}
                      onChange={() => props.setVideoDevice(2)}
                      id="vt2" />
                    <label className="radio" htmlFor="vt2">
                      <img
                        src="/art/blkcam.png"
                        style={{ marginTop: "10px", height: "60px" }}
                      />
                      <br />
                      HD Indoor Camera
                    </label>
                  </div>
                  <div style={{ display: "inline-block", width: "32%" }}>
                    <input type="radio" name="vidtab"
                      checked={props.videoDevice === 3}
                      onChange={() => props.setVideoDevice(3)}
                      id="vt3" />
                    <label className="radio" htmlFor="vt3">
                      <img
                        src="/art/odc.png"
                        style={{ marginTop: "10px", height: "60px" }}
                      />
                      <br />
                      HD Outdoor Camera
                    </label>
                  </div>
                </div>
              </center>
              <div
                className="monty"
                style={{
                  color: "#ffffff",
                  fontSize: "21px",
                  lineHeight: "25px",
                  fontWeight: 300,
                  paddingBottom: "10px"
                }}
              >
                Select 1 Free* Smart Device (click to select)
              </div>
              <div className="radios" style={{ margin: "2px 0px 10px 0px" }}>
                <div style={{ display: "inline-block", width: "32%" }}>
                  <input
                    type="radio"
                    name="smttab"
                    checked={props.smartDevice === 1}
                    onChange={() => props.setSmartDevice(1)}
                    id="st1"
                  />
                  <label className="radio" htmlFor="st1">
                    <img
                      src="/art/tmo.png"
                      style={{ marginTop: "10px", height: "60px" }}
                    />
                    <br />
                    Smart Thermostat
                  </label>
                </div>
                <div style={{ display: "inline-block", width: "32%" }}>
                  <input type="radio" name="smttab"
                    checked={props.smartDevice === 2}
                    onChange={() => props.setSmartDevice(2)}
                    id="st2" />
                  <label className="radio" htmlFor="st2">
                    <img
                      src="/art/sdl.png"
                      style={{ marginTop: "10px", height: "60px" }}
                    />
                    <br />
                    Smart Door Lock
                  </label>
                </div>
                <div style={{ display: "inline-block", width: "32%" }}>
                  <input type="radio" name="smttab"
                    checked={props.smartDevice === 3}
                    onChange={() => props.setSmartDevice(3)}
                    id="st3" />
                  <label className="radio" htmlFor="st3">
                    <img
                      src="/art/gar.png"
                      style={{ marginTop: "10px", height: "60px" }}
                    />
                    <br />
                    Smart Garage Control
                  </label>
                </div>
              </div>
              <center>
                <div
                  style={{
                    width: "150px",
                    display: "inline-block",
                    margin: "10px 0px 0px 10px"
                  }}
                >
                  <button className="greenhover">Next</button>
                </div>
              </center>
              <br />
            </form>
          </div>
        </div>
        {/* legal info */}
        <div
          className="montylegal"
          style={{
            textAlign: "center",
            padding: "25px 30px 30px 30px",
            width: "85%",
            maxWidth: "1000px",
            lineHeight: "15px"
          }}
        >
          *All equipment is free. $99 intallation and 36-month monitoring
          agreement required (24-month in CA). Early termination fee applies.
          <br />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Residential2Tablet;
