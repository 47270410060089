import React from 'react';
import Footer from '../Footer/Footer';

const Residential3Mobile = (props) => {

  const isDisabled = () => {
    const firstName = (/.{2,}/).test(props.firstName);
    const lastName = (/.{2,}/).test(props.lastName);
    const email = (/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/).test(props.email);
    const phone = (/^(?:\+1)?[2-9]\d{9}$/).test(props.phone);
    return (!(firstName && lastName && email && phone));
  }

  return (
    <div className="mob">
      {/* flag */}
      <div style={{ width: "100%", background: "#ffffff" }}>
        <div style={{ height: "100%", padding: "5px 0px 5px 0px" }}>
          <center>
            <img
              src="/art/combologo.png"
              alt="ADT Offer from Capital One"
              style={{ height: "45px" }}
            />
          </center>
        </div>
        <div align="left">
          <div
            className="monty"
            style={{
              color: "#000000",
              fontSize: "20px",
              lineHeight: "24px",
              letterSpacing: "-0.01em",
              fontWeight: 400,
              padding: "0px 20px 10px 20px",
              textAlign: "center"
            }}
          >
            To finish, complete and submit your
            contact&nbsp;information&nbsp;below.
          </div>
          <div
            style={{
              width: "100%",
              backgroundColor: "#fdb539",
              padding: "15px 0px 15px 0px",
              textAlign: "center"
            }}
          >
            <div
              className="monty"
              style={{
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: 300,
                paddingRight: 10
              }}
            >
              <a
                onClick={props.prevPageUpgrades}
                style={{
                  color: "#333333",
                  textDecoration: "none",
                  textShadow: "none",
                  cursor: "pointer"
                }}
              >
                Bonus Equipment [ click to change ]<br />

                <strong>{props.videoDevice === 1 && <>
                  Video Doorbell
                </>}
                  {props.videoDevice === 2 && <>
                    HD Indoor Camera
                  </>}
                  {props.videoDevice === 3 && <>
                    HD Outdoor Camera
                  </>}</strong> and{" "}
                <strong>
                  {props.smartDevice === 1 && <>
                    Smart Thermostat
                  </>}
                  {props.smartDevice === 2 && <>
                    Smart Door Lock
                  </>}
                  {props.smartDevice === 3 && <>
                    Smart Garage Control
                  </>}
                </strong>
              </a>
            </div>
            <div
              className="monty"
              style={{
                fontSize: "15px",
                lineHeight: "20px",
                marginTop: "10px",
                fontWeight: 300,
                paddingRight: 10
              }}
            >
              <a
                onClick={props.prevPageZip}
                style={{
                  color: "#333333",
                  textDecoration: "none",
                  textShadow: "none",
                  cursor: "pointer"
                }}
              >
                ZIP Code: <strong>{props.zipCode}</strong>
                &nbsp;&nbsp;[ change ]
              </a>
            </div>
          </div>
          <div style={{ width: "100%", backgroundColor: "#005dab" }}>
            <div style={{ padding: "25px 40px 30px 30px" }}>
              <div
                className="monty"
                style={{
                  color: "#ffffff",
                  fontSize: "15px",
                  lineHeight: "18px",
                  fontWeight: 400
                }}
              >
                Please provide all information.
              </div>
              <div style={{ marginTop: "15px" }} />
              <form>
                <div style={{ width: "100%" }}>
                  <input
                    type="text"
                    style={{ width: "90%" }}
                    name="first"
                    placeholder="First Name"
                    data-corners="false"
                    value={props.firstName}
                    onChange={(e) => props.setFirstName(e.target.value)}
                  />
                </div>
                <div style={{ width: "100%", marginTop: "10px" }}>
                  <input
                    type="text"
                    style={{ width: "90%" }}
                    name="last"
                    placeholder="Last Name"
                    data-corners="false"
                    value={props.lastName}
                    onChange={(e) => props.setLastName(e.target.value)}
                  />
                </div>
                <div style={{ width: "100%", marginTop: "10px" }}>
                  <input
                    type="text"
                    style={{ width: "90%" }}
                    name="email"
                    placeholder="Email"
                    data-corners="false"
                    value={props.email}
                    onChange={(e) => props.setEmail(e.target.value)}
                  />
                </div>
                <div style={{ width: "100%", marginTop: "10px" }}>
                  <input
                    type="text"
                    style={{ width: "90%" }}
                    name="phone"
                    placeholder="Phone (Numbers Only)"
                    data-corners="false"
                    maxLength={10}
                    value={props.phone}
                    onChange={(e) => props.setPhone(e.target.value)}
                  />
                </div>
                {/* <div style="width:150px; margin-top:10px; "><button class="greenhover">Submit</button></div> */}
                <div
                  className="monty"
                  style={{
                    color: "#ffffff",
                    fontSize: "15px",
                    lineHeight: "18px",
                    fontWeight: 400,
                    padding: "15px 0px 5px 0px"
                  }}
                >
                  Are you a homeowner?
                </div>
                <div
                  style={{ display: "inline-block", margin: "10px 20px 0px 0px" }}
                >
                  <button
                    className="greenhover"
                    style={{ display: "inline-block" }}
                    name="hown"
                    value="yes"
                    onClick={props.nextPageHomeowner}
                    disabled={isDisabled()}
                  >
                    YES
                  </button>
                </div>
                <div style={{ display: "inline-block", marginTop: "10px" }}>
                  <button
                    className="greenhover"
                    style={{ display: "inline-block" }}
                    name="hown"
                    value="no"
                    onClick={props.nextPageRenter}
                    disabled={isDisabled()}
                  >
                    NO
                  </button>
                </div>
              </form>
              <div
                className="montylegal"
                style={{
                  paddingTop: "20px",
                  color: "#ffffff",
                  fontWeight: 300,
                  letterSpacing: "0.02em",
                  fontSize: "12px",
                  lineHeight: "15px"
                }}
              >
                By submitting your information above, you acknowledge and provide
                your consent to receive calls from your ADT Authorized Dealer at
                the number you submitted. Your consent is not a commitment to
                purchase.
              </div>
            </div>
          </div>
          <div
            className="montylegal"
            style={{ margin: "20px 30px 20px 30px", textAlign: "center" }}
          >
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Residential3Mobile;
