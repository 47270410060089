import React from 'react';
import Footer from '../Footer/Footer';

const Residential3Desktop = (props) => {

  const isDisabled = () => {
    const firstName = (/.{2,}/).test(props.firstName);
    const lastName = (/.{2,}/).test(props.lastName);
    const email = (/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/).test(props.email);
    const phone = (/^(?:\+1)?[2-9]\d{9}$/).test(props.phone);
    return (!(firstName && lastName && email && phone));
  }

  return (
    <div className="pc">
      <center>
        <div style={{ width: "100%", maxWidth: "1200px", marginBottom: "100px" }}>
          {/* flag */}
          <div style={{ width: "100%", height: "90px", background: "#ffffff" }}>
            <div style={{ height: "100%", paddingBottom: "0px" }}>
              <div style={{ float: "left", width: "50%" }} align="left">
                <img
                  src="/art/authdlrlogo.jpg"
                  alt="ADT Authorized Dealer"
                  style={{ height: "45px", padding: "23px 0px 0px 53px" }}
                />
              </div>
              <div style={{ float: "left", width: "50%" }} align="right">
                <img
                  src="/art/cospringlogo.jpg"
                  alt="Capital One Business Deals"
                  style={{ width: "250px", padding: "23px 90px 0px 0px" }}
                />
              </div>
              <div style={{ clear: "both" }} />
            </div>
            <div align="left">
              <center>
                <div
                  className="monty"
                  style={{
                    width: "85%",
                    color: "#000000",
                    fontSize: "33px",
                    lineHeight: "33px",
                    fontWeight: 400,
                    padding: "20px 20px 30px 20px"
                  }}
                >
                  Please provide your contact information below to finish.
                </div>
              </center>
              <div
                style={{
                  display: "inline-block",
                  width: "44%",
                  maxWidth: "500px",
                  verticalAlign: "top",
                  paddingTop: "30px"
                }}
              >
                <center>
                  <div
                    className="monty"
                    style={{
                      width: "75%",
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: 300
                    }}
                  >
                    FREE* home security system
                  </div>
                  <img src="/art/basebase.jpg" style={{ width: "70%" }} />
                  <div
                    className="monty"
                    style={{
                      width: "75%",
                      fontSize: "24px",
                      lineHeight: "28px",
                      marginTop: "20px",
                      fontWeight: 300
                    }}
                  >
                    FREE* bonus equipment
                  </div>
                  <div
                    className="monty"
                    style={{
                      display: "inline-block",
                      width: "46%",
                      verticalAlign: "top",
                      fontSize: "15px",
                      fontWeight: 300
                    }}
                    align="center"
                  >
                    {props.videoDevice === 1 && <>
                      <img
                        src="/art/vdb.png"
                        style={{ marginTop: "10px", height: "120px" }}
                      />
                      <br />
                      Video Doorbell
                    </>}
                    {props.videoDevice === 2 && <>
                      <img
                        src="/art/blkcam.png"
                        style={{ marginTop: "10px", height: "120px" }}
                      />
                      <br />
                      HD Indoor Camera
                    </>}
                    {props.videoDevice === 3 && <>
                      <img
                        src="/art/odc.png"
                        style={{ marginTop: "10px", height: "120px" }}
                      />
                      <br />
                      HD Outdoor Camera
                    </>}
                  </div>
                  <div
                    className="monty"
                    style={{
                      display: "inline-block",
                      width: "46%",
                      verticalAlign: "top",
                      fontSize: "15px",
                      fontWeight: 300
                    }}
                    align="center"
                  >
                    {props.smartDevice === 1 && <>
                      <img
                        src="/art/tmo.png"
                        style={{ marginTop: "10px", height: "120px" }}
                      />
                      <br />
                      Smart Thermostat
                    </>}
                    {props.smartDevice === 2 && <>
                      <img
                        src="/art/sdl.png"
                        style={{ marginTop: "10px", height: "120px" }}
                      />
                      <br />
                      Smart Door Lock
                    </>}
                    {props.smartDevice === 3 && <>
                      <img
                        src="/art/gar.png"
                        style={{ marginTop: "10px", height: "120px" }}
                      />
                      <br />
                      Smart Garage Control
                    </>}
                  </div>
                  <center>
                    <div
                      className="monty"
                      style={{
                        width: "50%",
                        margin: "30px 0px 0px 0px",
                        backgroundColor: "#fdb539",
                        padding: "30px",
                        color: "#333333",
                        fontSize: "17px",
                        lineHeight: "23px",
                        fontWeight: 300
                      }}
                    >
                      <a
                        onClick={props.prevPageUpgrades}
                        style={{
                          color: "#666666",
                          textDecoration: "none",
                          textShadow: "none",
                          cursor: "pointer"
                        }}
                      >
                        Change Bonus Equipment
                      </a>
                    </div>
                  </center>
                </center>
              </div>
              <div style={{ display: "inline-block", width: "52%" }}>
                <div
                  style={{
                    padding: "20px 40px 40px 40px",
                    backgroundColor: "#005dab"
                  }}
                >
                  {/* <div class="monty" style="color:#ffffff; font-size:20px; line-height:25px; font-weight:400; ">Please provide your contact information to finish.</div> */}
                  <div style={{ marginTop: "20px" }}>
                    <form>
                      <div
                        style={{
                          width: "200px",
                          display: "inline-block",
                          marginRight: 20
                        }}
                      >
                        <input
                          type="text"
                          style={{ width: "90%" }}
                          name="first"
                          placeholder="*First Name"
                          data-corners="false"
                          value={props.firstName}
                          onChange={(e) => props.setFirstName(e.target.value)}
                        />
                        <div
                          data-lastpass-icon-root="true"
                          style={{
                            position: "relative !important",
                            height: "0px !important",
                            width: "0px !important",
                            float: "left !important"
                          }}
                        />
                      </div>
                      <div style={{ width: "200px", display: "inline-block" }}>
                        <input
                          type="text"
                          style={{ width: "90%" }}
                          name="last"
                          placeholder="*Last Name"
                          data-corners="false"
                          value={props.lastName}
                          onChange={(e) => props.setLastName(e.target.value)}
                        />
                      </div>
                      <br />
                      <div style={{ width: "400px", marginTop: "15px" }}>
                        <input
                          type="text"
                          style={{ width: "90%" }}
                          name="email"
                          placeholder="*Email"
                          data-corners="false"
                          value={props.email}
                          onChange={(e) => props.setEmail(e.target.value)}
                        />
                      </div>
                      <div
                        style={{
                          width: "200px",
                          display: "inline-block",
                          margin: "15px 20px 0px 0px"
                        }}
                      >
                        <input
                          type="text"
                          style={{ width: "90%" }}
                          name="phone"
                          placeholder="*Phone (Numbers Only)"
                          data-corners="false"
                          maxLength={10}
                          value={props.phone}
                          onChange={(e) => props.setPhone(e.target.value)}
                        />
                      </div>
                      <div
                        style={{
                          width: "150px",
                          display: "inline-block",
                          marginTop: "15px"
                        }}
                      >
                        &nbsp;{/* <button class="greenhover">Submit</button> */}
                      </div>
                      <br />
                      <div
                        style={{
                          width: "210px",
                          display: "inline-block",
                          margin: "15px 20px 0px 0px"
                        }}
                      >
                        <button className="greenhover" name="hown" value="yes"
                          disabled={isDisabled()}
                          onClick={props.nextPageHomeowner}
                        >
                          Yes - I confirm I am a Homeowner
                        </button>
                      </div>
                      <div
                        style={{
                          width: "210px",
                          display: "inline-block",
                          marginTop: "15px"
                        }}
                      >
                        <button className="greenhover" name="hown" value="no"
                          onClick={props.nextPageRenter}
                          disabled={isDisabled()}
                        >
                          No - I am not a Homeowner
                        </button>
                      </div>
                      <br />
                    </form>
                  </div>
                  <div
                    className="montylegal"
                    style={{
                      width: "85%",
                      padding: "20px 10px 0px 10px",
                      color: "#ffffff",
                      fontWeight: 300,
                      letterSpacing: "0.02em",
                      fontSize: "13px",
                      lineHeight: "17px"
                    }}
                  >
                    By submitting your information above, you acknowledge and
                    provide your consent to receive calls from your ADT Authorized
                    Dealer at the number you submitted. Your consent is not a
                    commitment to purchase.
                  </div>
                </div>
                <center>
                  <div
                    className="monty"
                    style={{
                      width: "50%",
                      margin: "30px 0px 0px 0px",
                      backgroundColor: "#fdb539",
                      padding: "30px",
                      color: "#333333",
                      fontSize: "17px",
                      lineHeight: "23px",
                      fontWeight: 300
                    }}
                  >
                    ZIP Code: <strong>{props.zipCode}</strong>
                    &nbsp;&nbsp;[{" "}
                    <a
                      onClick={props.prevPageZip}
                      style={{
                        color: "#333333",
                        textDecoration: "none",
                        textShadow: "none",
                        cursor: "pointer"
                      }}
                    >
                      Change ZIP
                    </a>{" "}
                    ]
                  </div>
                </center>
              </div>
              {/* legal info */}
              <center>
                <div
                  className="montylegal"
                  style={{
                    width: "100%",
                    padding: "20px 20px 50px 20px",
                    maxWidth: "1100px",
                    borderTop: "1px #cccccc solid",
                    marginTop: "20px"
                  }}
                >
                  *All equipment is free. $99 installation and 36-month monitoring
                  agreement required (24-month in CA). Early termination fee
                  applies.
                  <br />
                  <Footer />
                </div>
              </center>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default Residential3Desktop;
