import React from 'react';
import { useNavigate } from 'react-router-dom';
import SmallBiz1Mobile from '../components/SmallBiz1/Mobile';
import SmallBiz1Tablet from '../components/SmallBiz1/Tablet';
import SmallBiz1Desktop from '../components/SmallBiz1/Desktop';

const SmallBiz1 = (props) => {
  const navigate = useNavigate();

  const nextPage = () => {
    navigate('/smallbiz/contact');
  };

  return (
    <div style={{ width: "100%", marginBottom: 10 }}>
      <SmallBiz1Mobile nextPage={nextPage} {...props} />
      <SmallBiz1Tablet nextPage={nextPage} {...props} />
      <SmallBiz1Desktop nextPage={nextPage} {...props} />
    </div>

  );
};

export default SmallBiz1;
