import React from 'react';

const Residential4Tablet = (props) => {

  return (
    <div className="tab">
      {/* flag */}
      <div style={{ width: "100%", height: "90px", background: "#ffffff" }}>
        <div style={{ height: "100%", paddingBottom: "0px" }}>
          <div style={{ float: "left", width: "50%" }} align="left">
            <img
              src="/art/authdlrlogo.jpg"
              alt="ADT Authorized Dealer"
              style={{ height: "45px", padding: "23px 0px 0px 53px" }}
            />
          </div>
          <div style={{ float: "left", width: "50%" }} align="right">
            <img
              src="/art/cospringlogo.jpg"
              alt="Capital One Business Deals"
              style={{ width: "250px", padding: "23px 90px 0px 0px" }}
            />
          </div>
          <div style={{ clear: "both" }} />
        </div>
        <div align="left">
          <center>
            <div
              className="monty"
              style={{
                width: "80%",
                padding: "0px 40px 0px 40px",
                color: "#000000",
                fontSize: "3.5vw",
                lineHeight: "3.3vw",
                fontWeight: 400
              }}
            >
              Thank you for your interest in
              <br />
              an ADT Smart Home Security System!
            </div>
            <div
              className="monty"
              style={{
                width: "90%",
                padding: "30px 20px 0px 20px",
                color: "#000000",
                fontSize: "2.7vw",
                lineHeight: "3.1vw font-weight:400"
              }}
            >
              Please check your email for confirmation.
              <br />A representative from{" "}
              <strong>Home Security Alarm Experts</strong> will reach out to you
              shortly.
            </div>
            <div style={{ display: "inline-block", marginTop: "25px" }}>
              <button onClick={props.reset} className="greenhover">Reset</button>
            </div>
          </center>
        </div>
      </div>
    </div>
  );
};

export default Residential4Tablet;
