import React from 'react';

const Residential5Desktop = (props) => {

  return (
    <div className="pc">
      <center>
        <div style={{ width: "100%", maxWidth: "1200px", marginBottom: "100px" }}>
          {/* flag */}
          <div style={{ width: "100%", height: "90px", background: "#ffffff" }}>
            <div style={{ height: "100%", paddingBottom: "0px" }}>
              <div style={{ float: "left", width: "50%" }} align="left">
                <img
                  src="/art/authdlrlogo.jpg"
                  alt="ADT Authorized Dealer"
                  style={{ height: "45px", padding: "23px 0px 0px 53px" }}
                />
              </div>
              <div style={{ float: "left", width: "50%" }} align="right">
                <img
                  src="/art/cospringlogo.jpg"
                  alt="Capital One Business Deals"
                  style={{ width: "250px", padding: "23px 90px 0px 0px" }}
                />
              </div>
              <div style={{ clear: "both" }} />
            </div>
            <div align="left" style={{ height: "520px" }}>
              {/* #5197E8 */}
              <center>
                <div
                  style={{
                    width: "80%",
                    maxWidth: "800px",
                    marginTop: "30px",
                    backgroundColor: "#006ba9",
                    borderRadius: "20px"
                  }}
                >
                  <div style={{ padding: "60px 30px 60px 30px" }}>
                    <div
                      className="monty"
                      style={{
                        color: "#000000",
                        fontSize: "30px",
                        lineHeight: "33px",
                        fontWeight: 400
                      }}
                    >
                      Thank you for your interest.
                      <br />
                    </div>
                    <div
                      className="monty"
                      style={{
                        color: "#000000",
                        marginTop: "20px",
                        fontSize: "24px",
                        lineHeight: "30px",
                        fontWeight: 400
                      }}
                    >
                      Please note, this offer is designed for homeowners.
                      <br />
                      For home security systems designed for renters, click here:
                      <br />
                      <br />
                      <a
                        href="https://www.diyalarmexperts.com"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                      >
                        <div className="rentbutton" style={{ width: "60%" }}>
                          ADT Home Security Systems
                          <br />
                          For Renters
                        </div>
                      </a>
                      <br />A representative from
                      <br />
                      <span style={{ fontWeight: 600 }}>
                        Home Security Alarm Experts
                      </span>
                      <br />
                      will reach out to you shortly.
                      <br />
                      <br />
                    </div>
                    <div onClick={props.reset} style={{ display: "inline-block", marginTop: "25px" }}>
                      <button className="greenhover">Reset</button>
                    </div>
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default Residential5Desktop;
