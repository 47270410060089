import React from 'react';
import Footer from '../Footer/Footer';

const Residential2Desktop = (props) => {

  return (
    <div className="pc">
      <center>
        <div style={{ width: "100%", maxWidth: "1200px", marginBottom: "100px" }}>
          {/* flag */}
          <div style={{ width: "100%", height: "90px", background: "#ffffff" }}>
            <div style={{ height: "100%", paddingBottom: "0px" }}>
              <div style={{ float: "left", width: "50%" }} align="left">
                <img
                  src="/art/74c.jpg"
                  alt="ADT Authorized Dealer"
                  style={{ height: "45px", padding: "23px 0px 0px 53px" }}
                />
              </div>
              <div style={{ float: "left", width: "50%" }} align="right">
                <img
                  src="/art/cospringlogo.jpg"
                  alt="Capital One Business Deals"
                  style={{ width: "250px", padding: "23px 90px 0px 0px" }}
                />
              </div>
              <div style={{ clear: "both" }} />
            </div>
            <div align="left">
              <center>
                <div
                  className="monty"
                  style={{
                    width: "85%",
                    color: "#000000",
                    fontSize: "33px",
                    lineHeight: "39px",
                    fontWeight: 400,
                    padding: "20px"
                  }}
                >
                  You get a complete home security system plus 2 premium upgrades
                  from&nbsp;your&nbsp;ADT&nbsp;Authorized&nbsp;Dealer –{" "}
                  <span style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                    Home Security Alarm Experts
                  </span>
                </div>
              </center>
              <div
                style={{
                  display: "inline-block",
                  width: "44%",
                  maxWidth: "500px",
                  verticalAlign: "top",
                  paddingTop: "30px"
                }}
                align="center"
              >
                <div
                  className="monty"
                  style={{ fontSize: "27px", lineHeight: "30px", fontWeight: 400 }}
                >
                  Start with a complete home security system - an $850 value.
                </div>
                <img src="/art/basebase.jpg" style={{ width: "85%" }} />
                <br />
                <div
                  className="montylegal"
                  style={{ padding: "10px 25px 10px 25px" }}
                >
                  ADT Smart Home Security System includes 1 Command Control Panel,
                  3 Door/Window Sensors, 1 Motion Sensor, Battery Backup, Cellular
                  Connection, Mobile Application, Window Stickers and Outdoor
                  Signage, PLUS 1 Video Device and 1 Smart Device.
                </div>
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "52%",
                  verticalAlign: "top"
                }}
              >
                <div
                  style={{
                    padding: "35px 40px 40px 40px",
                    backgroundColor: "#005dab"
                  }}
                >
                  <center>
                    <div
                      className="monty"
                      style={{
                        color: "#ffffff",
                        fontSize: "27px",
                        lineHeight: "30px",
                        fontWeight: 400
                      }}
                    >
                      Add 2 FREE Premium Upgrades
                    </div>
                  </center>
                  <div style={{ marginTop: "20px" }}>
                    <form onSubmit={props.nextPage}>
                      <div
                        className="monty"
                        style={{
                          color: "#ffffff",
                          fontSize: "18px",
                          lineHeight: "24px",
                          fontWeight: 400
                        }}
                      >
                        Select 1 Free* Video Device
                      </div>
                      <div
                        className="radios"
                        style={{ margin: "2px 0px 10px 0px" }}
                      >
                        <div style={{ display: "inline-block", width: "32%" }}>
                          <input
                            type="radio"
                            name="vidpc"
                            id="vpc1"
                            checked={props.videoDevice === 1}
                            onChange={() => props.setVideoDevice(1)}
                          />
                          <label className="radio" htmlFor="vpc1">
                            <img
                              src="/art/vdb.png"
                              style={{ marginTop: "10px", height: "60px" }}
                            />
                            <br />
                            Video Doorbell
                          </label>
                        </div>
                        <div style={{ display: "inline-block", width: "32%" }}>
                          <input
                            type="radio"
                            name="vidpc"
                            checked={props.videoDevice === 2}
                            onChange={() => props.setVideoDevice(2)}
                            id="vpc2"
                          />
                          <label className="radio" htmlFor="vpc2">
                            <img
                              src="/art/blkcam.png"
                              style={{ marginTop: "10px", height: "60px" }}
                            />
                            <br />
                            HD Indoor Camera
                          </label>
                        </div>
                        <div style={{ display: "inline-block", width: "32%" }}>
                          <input
                            type="radio"
                            name="vidpc"
                            checked={props.videoDevice === 3}
                            onChange={() => props.setVideoDevice(3)}
                            id="vpc3"
                          />
                          <label className="radio" htmlFor="vpc3">
                            <img
                              src="/art/odc.png"
                              style={{ marginTop: "10px", height: "60px" }}
                            />
                            <br />
                            HD Outdoor Camera
                          </label>
                        </div>
                      </div>
                      <div
                        className="monty"
                        style={{
                          color: "#ffffff",
                          fontSize: "18px",
                          lineHeight: "24px",
                          fontWeight: 400
                        }}
                      >
                        Select 1 Free* Smart Device
                      </div>
                      <div
                        className="radios"
                        style={{ margin: "2px 0px 10px 0px" }}
                      >
                        <div style={{ display: "inline-block", width: "32%" }}>
                          <input
                            type="radio"
                            name="smtpc"
                            checked={props.smartDevice === 1}
                            onChange={() => props.setSmartDevice(1)}
                            id="spc1"
                          // defaultChecked="checked"
                          />
                          <label className="radio" htmlFor="spc1">
                            <img
                              src="/art/tmo.png"
                              style={{ marginTop: "10px", height: "60px" }}
                            />
                            <br />
                            Smart Thermostat
                          </label>
                        </div>
                        <div style={{ display: "inline-block", width: "32%" }}>
                          <input
                            type="radio"
                            name="smtpc"
                            checked={props.smartDevice === 2}
                            onChange={() => props.setSmartDevice(2)}
                            id="spc2"
                          />
                          <label className="radio" htmlFor="spc2">
                            <img
                              src="/art/sdl.png"
                              style={{ marginTop: "10px", height: "60px" }}
                            />
                            <br />
                            Smart Door Lock
                          </label>
                        </div>
                        <div style={{ display: "inline-block", width: "32%" }}>
                          <input
                            type="radio"
                            name="smtpc"
                            checked={props.smartDevice === 3}
                            onChange={() => props.setSmartDevice(3)}
                            id="spc3"
                          />
                          <label className="radio" htmlFor="spc3">
                            <img
                              src="/art/gar.png"
                              style={{ marginTop: "10px", height: "60px" }}
                            />
                            <br />
                            Smart Garage Control
                          </label>
                        </div>
                      </div>
                      <center>
                        <div
                          style={{
                            width: "150px",
                            display: "inline-block",
                            margin: "10px 0px 0px 10px"
                          }}
                        >
                          <button className="greenhover">Next</button>
                        </div>
                      </center>
                    </form>
                  </div>
                </div>
                <center>
                  <div
                    className="monty"
                    style={{
                      width: "50%",
                      margin: "30px 0px 0px 0px",
                      backgroundColor: "#fdb539",
                      padding: "30px",
                      color: "#333333",
                      fontSize: "17px",
                      lineHeight: "23px",
                      fontWeight: 300
                    }}
                  >
                    ZIP Code: <strong>{props.zipCode}</strong>&nbsp;&nbsp;[{" "}
                    <a
                      onClick={props.prevPage}
                      style={{
                        color: "#333333",
                        textDecoration: "none",
                        textShadow: "none",
                        cursor: "pointer"
                      }}
                    >
                      change{" "}
                    </a>{" "}
                    ]
                  </div>
                </center>
              </div>
              {/* legal info */}
              <center>
                <div
                  className="montylegal"
                  style={{
                    width: "95%",
                    padding: "20px 20px 50px 20px",
                    maxWidth: "1100px",
                    borderTop: "1px #cccccc solid",
                    marginTop: "20px"
                  }}
                >
                  *All equipment is free. $99 installation and 36-month monitoring
                  agreement required. Early termination fee applies.
                  <br />
                  <Footer />
                </div>
              </center>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default Residential2Desktop;
