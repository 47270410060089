import React from 'react';
import Footer from '../Footer/Footer';

const SmallBiz2Tablet = (props) => {

  const isDisabled = () => {
    const firstName = (/.{2,}/).test(props.firstName);
    const lastName = (/.{2,}/).test(props.lastName);
    const email = (/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/).test(props.email);
    const phone = (/^(?:\+1)?[2-9]\d{9}$/).test(props.phone);
    return (!(firstName && lastName && email && phone));
  }

  return (
    <div className="tab">
      {/* flag */}
      <div style={{ width: '100%', height: '90px', background: '#ffffff' }}>
        <div style={{ height: '100%', paddingBottom: '0px' }}>
          <div style={{ float: 'left', width: '50%' }} align="left"><img src="/art/74c.jpg" alt="ADT Authorized Dealer" style={{ height: '45px', padding: '23px 0px 0px 53px' }} /></div>
          <div style={{ float: 'left', width: '50%' }} align="right"><img src="/art/cospringlogo.jpg" alt="Capital One Business Deals" style={{ width: '250px', padding: '23px 90px 0px 0px' }} /></div>
          <div style={{ clear: 'both' }} />
        </div>
        <div align="left">
          <center>
            <div className="monty" style={{ width: '85%%', padding: '0px 20px 0px 20px', color: '#000000', fontSize: '3.7vw', lineHeight: '4.1vw font-weight:400' }}>Get a Free* Small Business Security System from your ADT Authorized Dealer Home Security Alarm Experts</div>
            <div><img src="/art/basesmb.jpg" style={{ width: '80%' }} /></div>
            {/* <div class="montylegal" style="width:80%; padding:10px 30px 10px 30px; line-height:12px; ">ADT Small Business Security System includes 1 Command Control Panel, 3 door/window sensors, 1 motion sensor, 3 indoor camera, cellular backup, mobile application, window stickers and outdoor sign.</div> */}
          </center>
          <div style={{ width: '100%', backgroundColor: '#005dab' }}>
            <div style={{ padding: '20px 50px 30px 50px' }}>
              <div className="monty" style={{ margin: '20px 20px 30px 0px', color: '#ffffff', fontSize: '18px', lineHeight: '25px', fontWeight: 300 }}>ZIP Code: <strong>{props.zipCode}</strong>&nbsp;&nbsp;[ <a onClick={props.prevPage} style={{ color: '#ffffff', textDecoration: 'none', textShadow: 'none', cursor: "pointer" }}>change zip</a> ]</div>
              <div className="monty" style={{ color: '#ffffff', fontSize: '18px', lineHeight: '24px', fontWeight: 300 }}>Please complete and submit your contact information below.</div>
              <div style={{ marginTop: '20px' }}>
                <form>
                  <div style={{ width: '240px', display: 'inline-block', marginRight: '20px' }}>
                    <input
                      type="text"
                      style={{ width: "90%" }}
                      name="first"
                      placeholder="*First Name"
                      data-corners="false"
                      value={props.firstName}
                      onChange={(e) => props.setFirstName(e.target.value)}
                    />
                  </div><div style={{ width: '240px', display: 'inline-block' }}>
                    <input
                      type="text"
                      style={{ width: "90%" }}
                      name="last"
                      placeholder="*Last Name"
                      data-corners="false"
                      value={props.lastName}
                      onChange={(e) => props.setLastName(e.target.value)}
                    />
                  </div><br />
                  <div style={{ width: '450px', marginTop: '15px' }}>
                    <input
                      type="text"
                      style={{ width: "90%" }}
                      name="email"
                      placeholder="*Email"
                      data-corners="false"
                      value={props.email}
                      onChange={(e) => props.setEmail(e.target.value)}
                    />
                  </div>
                  <div style={{ width: '240px', display: 'inline-block', margin: '15px 20px 0px 0px' }}>
                    <input
                      type="text"
                      style={{ width: "90%" }}
                      name="phone"
                      placeholder="*Phone (Numbers Only)"
                      data-corners="false"
                      maxLength={10}
                      value={props.phone}
                      onChange={(e) => props.setPhone(e.target.value)}
                    />
                  </div><div style={{ width: '150px', display: 'inline-block', marginTop: '15px' }}>
                    <button className="greenhover" name="hown" value="no"
                      onClick={props.nextPage}
                      disabled={isDisabled()}
                    >Submit</button>
                  </div><br />
                </form>
              </div>
              <div className="montylegal" style={{ width: '65%', paddingTop: '15px', color: '#ffffff', fontWeight: 300, letterSpacing: '0.02em', fontSize: '12px', lineHeight: '15px' }}>By clicking the "SUBMIT" button, you acknowledge and provide your consent to receive calls from your ADT Authorized Dealer at the number you submit. Your consent is not a commitment to purchase.</div>
            </div>
          </div>
          {/* legal info */}
          <div className="montylegal" style={{ backgroundColor: '#ffffff', textAlign: 'center', padding: '20px 30px 20px 30px', width: '90%', lineHeight: '15px' }}>
            *All equipment is free. $99 installation and 36-month monitoring agreement required. Early termination applies. ADT Small Business Security System includes 1 Command Control Panel, 3 door/window sensors, 1 motion sensor, 3 indoor cameras, cellular backup, mobile application, window stickers and outdoor sign.<br />
            <Footer /></div>
        </div>
      </div>
    </div>

  );
};

export default SmallBiz2Tablet;
