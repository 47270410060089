import React from 'react';
import Footer from '../Footer/Footer';

const Residential3Tablet = (props) => {

  const isDisabled = () => {
    const firstName = (/.{2,}/).test(props.firstName);
    const lastName = (/.{2,}/).test(props.lastName);
    const email = (/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/).test(props.email);
    const phone = (/^(?:\+1)?[2-9]\d{9}$/).test(props.phone);
    return (!(firstName && lastName && email && phone));
  }

  return (
    <div className="tab">
      {/* flag */}
      <div style={{ width: "100%", height: "90px", background: "#ffffff" }}>
        <div style={{ height: "100%", paddingBottom: "0px" }}>
          <div style={{ float: "left", width: "50%" }} align="left">
            <img
              src="/art/authdlrlogo.jpg"
              alt="ADT Authorized Dealer"
              style={{ height: "45px", padding: "23px 0px 0px 53px" }}
            />
          </div>
          <div style={{ float: "left", width: "50%" }} align="right">
            <img
              src="/art/cospringlogo.jpg"
              alt="Capital One Business Deals"
              style={{ width: "250px", padding: "23px 90px 0px 0px" }}
            />
          </div>
          <div style={{ clear: "both" }} />
        </div>
        <div align="left">
          <center>
            <div
              className="monty"
              style={{
                width: "80%",
                color: "#000000",
                fontSize: "3.5vw",
                lineHeight: "3.3vw",
                fontWeight: 400
              }}
            >
              Just complete and submit your contact&nbsp;information&nbsp;below.
            </div>
          </center>
          <center>
            <div style={{ color: "#000000", padding: "20px 30px 20px 30px" }}>
              <div className="ui-grid-b">
                <div
                  className="ui-block-a"
                  style={{ width: "38%", verticalAlign: "top" }}
                  align="center"
                >
                  <p className="monty">Smart Home Security System</p>
                  <img
                    src="/art/basebase.jpg"
                    alt="Base System"
                    style={{ width: "85%" }}
                  />
                </div>
                <div
                  className="ui-block-b"
                  style={{ width: "38%", verticalAlign: "top" }}
                  align="center"
                >
                  <p className="monty">Bonus Equipment</p>
                  <div
                    style={{ display: "inline-block", width: "48%" }}
                    align="center"
                  >
                    {props.videoDevice === 1 && <>
                      <img
                        src="/art/vdb.png"
                        style={{ marginTop: "10px", height: "120px" }}
                      />
                      <br />
                      Video Doorbell
                    </>}
                    {props.videoDevice === 2 && <>
                      <img
                        src="/art/blkcam.png"
                        style={{ marginTop: "10px", height: "120px" }}
                      />
                      <br />
                      HD Indoor Camera
                    </>}
                    {props.videoDevice === 3 && <>
                      <img
                        src="/art/odc.png"
                        style={{ marginTop: "10px", height: "120px" }}
                      />
                      <br />
                      HD Outdoor Camera
                    </>}
                  </div>
                  <div
                    style={{ display: "inline-block", width: "48%" }}
                    align="center"
                  >
                    {props.smartDevice === 1 && <>
                      <img
                        src="/art/tmo.png"
                        style={{ marginTop: "10px", height: "120px" }}
                      />
                      <br />
                      Smart Thermostat
                    </>}
                    {props.smartDevice === 2 && <>
                      <img
                        src="/art/sdl.png"
                        style={{ marginTop: "10px", height: "120px" }}
                      />
                      <br />
                      Smart Door Lock
                    </>}
                    {props.smartDevice === 3 && <>
                      <img
                        src="/art/gar.png"
                        style={{ marginTop: "10px", height: "120px" }}
                      />
                      <br />
                      Smart Garage Control
                    </>}
                  </div>
                  <div className="monty" style={{ fontSize: "15px" }}>
                    [{" "}
                    <a
                      onClick={props.prevPageUpgrades}
                      style={{
                        color: "#999999",
                        textDecoration: "none",
                        textShadow: "none",
                        cursor: "pointer"
                      }}
                    >
                      change
                    </a>{" "}
                    ]
                  </div>
                </div>
                <div
                  className="ui-block-c"
                  style={{ width: "20%", verticalAlign: "bottom" }}
                  align="center"
                >
                  <p className="monty">ZIP Code</p>
                  <div
                    className="montyhead"
                    style={{ fontSize: "25px", padding: "35px 0px 65px 0px" }}
                  >
                    <strong>{props.zipCode}</strong>
                  </div>
                  <div className="monty" style={{ fontSize: "15px" }}>
                    [{" "}
                    <a
                      onClick={props.prevPageZip}
                      style={{
                        color: "#999999",
                        textDecoration: "none",
                        textShadow: "none",
                        cursor: "pointer"
                      }}
                    >
                      change
                    </a>{" "}
                    ]
                  </div>
                </div>
              </div>
            </div>
          </center>
          <div style={{ width: "100%", backgroundColor: "#005dab" }}>
            <div style={{ padding: "20px 50px 40px 50px" }}>
              <div className="monty" style={{ color: "#ffffff" }}>
                Please provide all information.
              </div>
              <div style={{ marginTop: "20px" }}>
                <form>
                  <div
                    style={{
                      width: "240px",
                      display: "inline-block",
                      marginRight: 20
                    }}
                  >
                    <input
                      type="text"
                      style={{ width: "90%" }}
                      name="first"
                      placeholder="First Name"
                      data-corners="false"
                      value={props.firstName}
                      onChange={(e) => props.setFirstName(e.target.value)}
                    />
                  </div>
                  <div style={{ width: "240px", display: "inline-block" }}>
                    <input
                      type="text"
                      style={{ width: "90%" }}
                      name="last"
                      placeholder="Last Name"
                      data-corners="false"
                      value={props.lastName}
                      onChange={(e) => props.setLastName(e.target.value)}
                    />
                  </div>
                  <br />
                  <div style={{ width: "450px", marginTop: "15px" }}>
                    <input
                      type="text"
                      style={{ width: "90%" }}
                      name="email"
                      placeholder="Email"
                      data-corners="false"
                      value={props.email}
                      onChange={(e) => props.setEmail(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      width: "240px",
                      display: "inline-block",
                      margin: "15px 20px 0px 0px"
                    }}
                  >
                    <input
                      type="text"
                      style={{ width: "90%" }}
                      name="phone"
                      placeholder="Phone (Numbers Only)"
                      data-corners="false"
                      maxLength={10}
                      value={props.phone}
                      onChange={(e) => props.setPhone(e.target.value)}
                    />
                  </div>
                  <div
                    style={{ width: "150px", display: "inline-block", marginTop: "15px" }}
                  >
                    &nbsp;{/* <button class="greenhover">Submit</button> */}
                  </div>
                  <br />
                  <div
                    style={{
                      width: "240px",
                      display: "inline-block",
                      margin: "15px 20px 0px 0px"
                    }}
                  >
                    <button className="greenhover" name="hown" value="yes"
                      onClick={props.nextPageHomeowner}
                      disabled={isDisabled()}
                    >
                      Yes - I confirm I am a Homeowner
                    </button>
                  </div>
                  <div
                    style={{ width: "240px", display: "inline-block", marginTop: "15px" }}
                  >
                    <button className="greenhover" name="hown" value="no"
                      onClick={props.nextPageRenter}
                      disabled={isDisabled()}
                    >
                      No - I am not a Homeowner
                    </button>
                  </div>
                  <br />
                </form>
              </div>
              <div
                className="montylegal"
                style={{
                  width: "65%",
                  padding: "15px 10px 0px 10px",
                  color: "#ffffff",
                  fontWeight: 300,
                  letterSpacing: "0.02em",
                  fontSize: "13px",
                  lineHeight: "17px"
                }}
              >
                By submitting your information above, you acknowledge and provide
                your consent to receive calls from your ADT Authorized Dealer at
                the number you submitted. Your consent is not a commitment to
                purchase.
              </div>
            </div>
          </div>
          {/* legal info */}
          <div
            className="montylegal"
            style={{
              backgroundColor: "#ffffff",
              textAlign: "center",
              padding: "20px 30px 20px 30px",
              width: "90%",
              lineHeight: "15px"
            }}
          >
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Residential3Tablet;
