import React from 'react';
import Footer from '../Footer/Footer';

const Residential1Desktop = (props) => {

  return (
    <div className="pc">
      <center>
        <div style={{ width: "100%", maxWidth: 1200, marginBottom: 100 }}>
          {/* flag */}
          <div style={{ width: "100%", height: 90, background: "#ffffff" }}>
            <div style={{ height: "100%", paddingBottom: 0 }}>
              <div style={{ float: "left", width: "50%" }} align="left">
                <img
                  src="/art/authdlrlogo.jpg"
                  alt="ADT Authorized Dealer"
                  style={{ height: 45, padding: "23px 0px 0px 53px" }}
                />
              </div>
              <div style={{ float: "left", width: "50%" }} align="right">
                <img
                  src="/art/cospringlogo.jpg"
                  alt="Capital One Business Deals"
                  style={{ width: 250, padding: "23px 90px 0px 0px" }}
                />
              </div>
              <div style={{ clear: "both" }} />
            </div>
            <div align="left">
              <div
                style={{
                  width: "100%",
                  backgroundImage: 'url("/art/resimg.jpg")',
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "left top",
                  backgroundColor: "#005dab",
                  height: 520
                }}
              >
                <div style={{ padding: "40px 30px 30px 470px" }}>
                  <div
                    className="monty"
                    style={{ color: "#ffffff", fontSize: 33, fontWeight: 600 }}
                  >
                    Welcome Capital One
                    <span style={{ fontWeight: 300, fontSize: 27 }}>®</span>{" "}
                    Business Deals
                    <span style={{ fontWeight: 300, fontSize: 27 }}>®</span>{" "}
                    Member!
                  </div>
                  <div
                    className="monty"
                    style={{
                      width: "90%",
                      marginTop: "20px",
                      color: "#ffffff",
                      fontSize: "25px",
                      lineHeight: "30px",
                      fontWeight: 300
                    }}
                  >
                    Homeowners - Get a FREE* smart security system plus
                    2&nbsp;FREE Premium Upgrades (up to $1,350 total value)
                    to&nbsp;protect your home and your home business.
                  </div>
                  <div
                    className="monty"
                    style={{
                      marginTop: 5,
                      color: "#86a3d7",
                      fontSize: "12px",
                      lineHeight: "16px",
                      fontWeight: 300
                    }}
                  >
                    *$99 installation fee and 36-month agreement required. Early
                    termination fee applies.
                  </div>
                  <div style={{ marginTop: 30 }}>
                    <form onSubmit={props.nextPage}>
                      <input
                        type="tel"
                        name="zip"
                        maxLength={5}
                        placeholder="ZIP Code"
                        data-corners="false"
                        value={props.zipCode}
                        onChange={(e) => props.setZipCode(e.target.value)}
                      />
                      <button
                        className="greenhover"
                        disabled={!(/[0-9]{5}/).test(props.zipCode)}
                        style={{ marginLeft: 15 }}>
                        Submit
                      </button>
                    </form>
                  </div>
                  <div
                    className="monty"
                    style={{
                      marginTop: "20px",
                      color: "#ffffff",
                      fontSize: "17px",
                      lineHeight: "32px",
                      fontWeight: 300,
                      letterSpacing: "0.02em"
                    }}
                  >
                    <img
                      src="/art/check.png"
                      alt="Check it out"
                      style={{ margin: "0px 8px -5px 0px" }}
                    />
                    FREE Home Security System PLUS Bonue Equipment (up to $1,350
                    value)
                    <br />
                    {/* <img src="/art/check.png" alt="Check it out" style="margin:0px 8px -5px 0px; " />FREE Bonus Equipment (up to $500 value)<br /> */}{" "}
                    <img
                      src="/art/check.png"
                      alt="Check it out"
                      style={{ margin: "0px 8px -5px 0px" }}
                    />
                    Choice of one video camera and one smart device
                    <br />
                    <img
                      src="/art/check.png"
                      alt="Check it out"
                      style={{ margin: "0px 8px -5px 0px" }}
                    />
                    24/7 Professional Monitoring by the world's #1 provider
                  </div>
                </div>
              </div>
            </div>
            {/* legal info */}
            <div
              className="montylegal"
              style={{
                backgroundColor: "#ffffff",
                textAlign: "left",
                padding: "20px 20px 50px 20px",
                maxWidth: 1200,
                borderTop: "1px #cccccc solid",
                marginTop: 20
              }}
            >
              <Footer />
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default Residential1Desktop;
