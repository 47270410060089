import React from 'react';
import Footer from '../Footer/Footer';

const SmallBiz3Desktop = (props) => {

  return (
    <center>
      <div className="pc" style={{ width: '100%', maxWidth: '1200px', marginBottom: '30px' }}>
        {/* flag */}
        <div style={{ width: '100%', height: '90px', background: '#ffffff' }}>
          <div style={{ height: '100%', paddingBottom: '0px' }}>
            <div style={{ float: 'left', width: '50%' }} align="left"><img src="/art/authdlrlogo.jpg" alt="ADT Authorized Dealer" style={{ height: '45px', padding: '23px 0px 0px 53px' }} /></div>
            <div style={{ float: 'left', width: '50%' }} align="right"><img src="/art/cospringlogo.jpg" alt="Capital One Bsuiness Deals" style={{ width: '250px', padding: '23px 90px 0px 0px' }} /></div>
            <div style={{ clear: 'both' }} />
          </div>
          <div align="left" style={{ height: '520px' }}>
            <div style={{ display: 'inline-block', width: '42%', maxWidth: '500px', height: '520px', verticalAlign: 'top', paddingTop: '30px' }} align="center"><img src="/art/basesmb.jpg" style={{ width: '90%' }} /><br />
              <div className="montylegal" style={{ padding: '10px 25px 10px 25px' }}>ADT Small Business Security System includes 1 Command Control Panel, 3 door/window sensors, 1 motion sensor, 3 indoor camera, battery backup, cellular communication, mobile application, window stickers and outdoor sign.</div></div>
            <div style={{ display: 'inline-block', width: '52%', maxWidth: '700px', backgroundColor: '#005dab', height: '520px' }}>
              <div style={{ padding: '40px 40px 30px 40px' }}>
                <div className="monty" style={{ color: '#ffffff', fontSize: '30px', lineHeight: '33px', fontWeight: 400 }}>Thank you for your interest in an ADT Small Business Security System.</div>
                <div className="monty" style={{ color: '#ffffff', fontSize: '24px', lineHeight: '30px', fontWeight: 300, paddingTop: '30px' }}>Please watch your email for request confirmation. A representative from your ADT Authorized Dealer Home Security Alarm Experts will reach out to you shortly.<br /><br />
                </div>
                <a onClick={props.reset}><div style={{ display: 'inline-block', marginTop: '15px' }}><button className="greenhover">Reset</button></div></a>
              </div></div>
            {/* legal info */}
            <div className="montylegal" style={{ width: '90%', backgroundColor: '#ffffff', textAlign: 'center', padding: '20px 20px 300px 20px', maxWidth: '1200px', borderTop: '1px #cccccc solid', marginTop: '20px' }}>
              <Footer /></div>
          </div>
        </div>
      </div></center>

  );
};

export default SmallBiz3Desktop;
