import React from 'react';
import Footer from '../Footer/Footer';

const Residential5Mobile = (props) => {

  return (
    <div className="mob">
      {/* flag */}
      <div style={{ width: "100%", background: "#ffffff" }}>
        <div style={{ height: "100%", padding: "5px 0px 5px 0px" }}>
          <center>
            <img
              src="/art/combologo.png"
              alt="ADT Offer from Capital One"
              style={{ height: "45px" }}
            />
          </center>
        </div>
        <div align="left">
          <div style={{ padding: "30px" }}>
            <center>
              <div
                className="monty"
                style={{
                  color: "#000000",
                  fontSize: "22px",
                  lineHeight: "28px",
                  fontWeight: 400
                }}
              >
                Thank you for your interest.
                <br />
              </div>
              <div
                className="monty"
                style={{
                  color: "#000000",
                  marginTop: "16px",
                  fontSize: "20px",
                  lineHeight: "20px",
                  fontWeight: 300
                }}
              >
                Please note, this offer is designed for homeowners. For home
                security systems designed for renters, click here:
                <br />
                <br />
                <a
                  href="https://www.diyalarmexperts.com"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <div className="rentbutton" style={{ width: "60%" }}>
                    ADT Home Security Systems
                    <br />
                    For Renters
                  </div>
                </a>
                <br />A representative from
                <br />
                <span style={{ fontWeight: 600 }}>
                  Home Security Alarm Experts
                </span>
                <br />
                will reach out to you shortly.
                <br />
                <br />
              </div>
            </center>
          </div>
          <center>
            <div onClick={props.reset} style={{ display: "inline-block", marginTop: "10px" }}>
              <button className="greenhover">Reset</button>
            </div>
          </center>
          <div
            className="montylegal"
            style={{
              margin: "20px 30px 20px 30px",
              paddingTop: "20px",
              borderTop: "1px #cccccc solid"
            }}
          >
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Residential5Mobile;
