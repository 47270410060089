import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import MainIntro from './pages/MainIntro';
import Residential1 from './pages/Residential1';
import Residential2 from './pages/Residential2';
import Residential3 from './pages/Residential3';
import Residential4 from './pages/Residential4';
import Residential5 from './pages/Residential5';
import SmallBiz1 from './pages/SmallBiz1';
import SmallBiz2 from './pages/SmallBiz2';
import SmallBiz3 from './pages/SmallBiz3';
// import Spring1 from './pages/Spring1';
// import Spring2 from './pages/Spring2';
// import Spring3 from './pages/Spring3';

const App = () => {

  const [zipCode, setZipCode] = useState(localStorage.getItem('zipCode') ?? '');
  const [videoDevice, setVideoDevice] = useState(localStorage.getItem('videoDevice') != null ? parseInt(localStorage.getItem('videoDevice')) : 1);
  const [smartDevice, setSmartDevice] = useState(localStorage.getItem('smartDevice') != null ? parseInt(localStorage.getItem('smartDevice')) : 1);
  const [firstName, setFirstName] = useState(localStorage.getItem('firstName') ?? '');
  const [lastName, setLastName] = useState(localStorage.getItem('lastName') ?? '');
  const [email, setEmail] = useState(localStorage.getItem('email') ?? '');
  const [phone, setPhone] = useState(localStorage.getItem('phone') ?? '');

  useEffect(() => { localStorage.setItem('zipCode', zipCode); }, [zipCode]);
  useEffect(() => { localStorage.setItem('videoDevice', videoDevice); }, [videoDevice]);
  useEffect(() => { localStorage.setItem('smartDevice', smartDevice); }, [smartDevice]);
  useEffect(() => { localStorage.setItem('firstName', firstName); }, [firstName]);
  useEffect(() => { localStorage.setItem('lastName', lastName); }, [lastName]);
  useEffect(() => { localStorage.setItem('email', email); }, [email]);
  useEffect(() => { localStorage.setItem('phone', phone); }, [phone]);

  const getVideoDevice = () => {
    switch (videoDevice) {
      case 1:
        return "Video Doorbell";
      case 2:
        return "HD Indoor Camera";
      default:
        return "HD Outdoor Camera"
    }
  }

  const getSmartDevice = () => {
    switch (smartDevice) {
      case 1:
        return "Smart Thermostat";
      case 2:
        return "Smart Door Lock";
      default:
        return "Smart Garage Control"
    }
  }

  const submit = async (isHomeowner, type) => {
    fetch('/api/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        zipCode: zipCode,
        videoDevice: type === 'Residential' ? getVideoDevice() : null,
        smartDevice: type === 'Residential' ? getSmartDevice() : null,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        isHomeowner: type === 'Residential' ? isHomeowner : null,
        type: type
      })
    })
  }

  const resetState = () => {
    setZipCode('');
    setVideoDevice(1);
    setSmartDevice(1);
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
  };

  return (
    <>
      <Routes>

        <Route
          element={
            <Residential1 zipCode={zipCode} setZipCode={setZipCode} />}
          exact={true}
          path="/residential"
        />
        <Route
          element={
            <Residential2 zipCode={zipCode} videoDevice={videoDevice} setVideoDevice={setVideoDevice} smartDevice={smartDevice} setSmartDevice={setSmartDevice} />}
          exact={true}
          path="/residential/upgrades"
        />
        <Route
          element={
            <Residential3 zipCode={zipCode} videoDevice={videoDevice} smartDevice={smartDevice} firstName={firstName} setFirstName={setFirstName}
              lastName={lastName} setLastName={setLastName} email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} submit={submit} />}
          exact={true}
          path="/residential/contact"
        />
        <Route
          element={
            <Residential4 resetState={resetState} />}
          exact={true}
          path="/residential/homeowner"
        />
        <Route
          element={
            <Residential5 resetState={resetState} />}
          exact={true}
          path="/residential/renter"
        />

        <Route
          element={
            <SmallBiz1 zipCode={zipCode} setZipCode={setZipCode} />}
          path="/smallbiz"
        />
        <Route
          element={
            <SmallBiz2 zipCode={zipCode} firstName={firstName} setFirstName={setFirstName} lastName={lastName} setLastName={setLastName}
              email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} submit={submit} />}
          exact={true}
          path="/smallbiz/contact"
        />
        <Route
          element={
            <SmallBiz3 resetState={resetState} />}
          exact={true}
          path="/smallbiz/thankyou"
        />

        {/* <Route
          element={
            <Spring1 zipCode={zipCode} setZipCode={setZipCode} />}
          path="/spring"
        />
        <Route
          element={
            <Spring2 zipCode={zipCode} firstName={firstName} setFirstName={setFirstName} lastName={lastName} setLastName={setLastName}
              email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} submit={submit} />}
          exact={true}
          path="/spring/contact"
        />
        <Route
          element={
            <Spring3 resetState={resetState} />}
          exact={true}
          path="/spring/thankyou"
        /> */}

        <Route
          Component={() => <MainIntro
          />}
          exact={true}
          path="/*"
        />

      </Routes>
    </>
  );
};

export default App;
