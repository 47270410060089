import React from 'react';
import Footer from '../Footer/Footer';

const SmallBiz1Desktop = (props) => {

  return (
    <center>
      <div className="pc" style={{ width: '100%', maxWidth: '1200px', marginBottom: '30px' }}>
        {/* flag */}
        <div style={{ width: '100%', height: '90px', background: '#ffffff' }}>
          <div style={{ height: '100%', paddingBottom: '0px' }}>
            <div style={{ float: 'left', width: '50%' }} align="left"><img src="/art/authdlrlogo.jpg" alt="ADT Authorized Dealer" style={{ height: '45px', padding: '23px 0px 0px 53px' }} /></div>
            <div style={{ float: 'left', width: '50%' }} align="right"><img src="/art/cospringlogo.jpg" alt="Capital One Bsuiness Deals" style={{ width: '250px', padding: '23px 90px 0px 0px' }} /></div>
            <div style={{ clear: 'both' }} />
          </div>
          <div align="left">
            <div style={{ width: '100%', backgroundImage: 'url("/art/smbizimg.jpg")', backgroundRepeat: 'no-repeat', backgroundPosition: 'left top', backgroundColor: '#005dab', height: '520px' }}>
              <div style={{ padding: '40px 30px 30px 470px' }}>
                <div className="monty" style={{ color: '#ffffff', fontSize: '33px', fontWeight: 600 }}>Welcome Capital One<span style={{ fontWeight: 300, fontSize: '27px' }}>®</span> Business Deals<span style={{ fontWeight: 300, fontSize: '27px' }}>®</span> Member!</div>
                <div className="monty" style={{ marginTop: '20px', color: '#ffffff', fontSize: '25px', lineHeight: '30px', fontWeight: 300 }}>Protect your business with a FREE* state-of-the-art commercial security system plus 3 FREE* Indoor Cameras ($1,600 value) and get 24/7&nbsp;monitoring for just $57.99 per month.</div>
                <div className="monty" style={{ marginTop: '5px', color: '#86a3d7', fontSize: '12px', lineHeight: '16px', fontWeight: 300 }}>*$99 installation fee and 36-month agreement (24-month in CA) required. Early termination fee applies.</div>
                <div style={{ marginTop: '30px' }}>
                  <form onSubmit={props.nextPage}>
                    <input
                      type="tel"
                      name="zip"
                      maxLength={5}
                      placeholder="ZIP Code"
                      data-corners="false"
                      value={props.zipCode}
                      onChange={(e) => props.setZipCode(e.target.value)}
                    />
                    <button
                      className="greenhover"
                      disabled={!(/[0-9]{5}/).test(props.zipCode)}
                      style={{ marginLeft: 15 }}>
                      Submit
                    </button>
                    <div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important' }} /></form>
                </div>
                <div className="monty" style={{ marginTop: '20px', color: '#ffffff', fontSize: '17px', lineHeight: '32px', fontWeight: 300, letterSpacing: '0.02em' }}>
                  <img src="/art/check.png" alt="Check it out" style={{ margin: '0px 8px -5px 0px' }} />Commercial Base Security System ($850 value)<br />
                  <img src="/art/check.png" alt="Check it out" style={{ margin: '0px 8px -5px 0px' }} />3 Nightvision, Motion-Activated Indoor Cameras ($750 value)<br />
                  <img src="/art/check.png" alt="Check it out" style={{ margin: '0px 8px -5px 0px' }} />24/7 Professional Monitoring by the world's #1 provider<br />
                  <img src="/art/check.png" alt="Check it out" style={{ margin: '0px 8px -5px 0px' }} />Cameras, Sensors, Remote Activation and more
                </div>
              </div>
            </div>
          </div>
          {/* legal info */}
          <center>
            <div className="montylegal" style={{ width: '95%', backgroundColor: '#ffffff', textAlign: 'center', padding: '20px 20px 300px 20px', maxWidth: '1200px', borderTop: '1px #cccccc solid', marginTop: '20px' }}>
              <Footer />
            </div></center>
        </div>
      </div>
    </center>
  );
};

export default SmallBiz1Desktop;
