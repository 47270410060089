import React from 'react';
import Footer from '../Footer/Footer';

const Residential1Tablet = (props) => {

  return (
    <div className="tab">
      {/* flag */}
      <div style={{ width: "100%", height: 90, background: "#ffffff" }}>
        <div style={{ height: "100%", paddingBottom: 0 }}>
          <div style={{ float: "left", width: "50%" }} align="left">
            <img
              src="/art/authdlrlogo.jpg"
              alt="ADT Authorized Dealer"
              style={{ height: 45, padding: "23px 0px 0px 53px" }}
            />
          </div>
          <div style={{ float: "left", width: "50%" }} align="right">
            <img
              src="/art/cospringlogo.jpg"
              alt="Capital One Business Deals"
              style={{ width: 250, padding: "23px 90px 0px 0px" }}
            />
          </div>
          <div style={{ clear: "both" }} />
        </div>
        <div align="left" style={{ backgroundColor: "#ffffff" }}>
          <div style={{ float: "left", width: "40%" }}>
            <img
              src="/art/resimg.jpg"
              alt="Smart Home Security Solutions"
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ float: "left", width: "60%" }}>
            <div style={{ padding: "20px 50px 20px 30px" }}>
              <div
                className="monty"
                style={{
                  color: "#000000",
                  fontSize: "3.7vw",
                  lineHeight: "3.7vw font-weight:600"
                }}
              >
                Welcome Capital One
                <span style={{ fontWeight: 300, fontSize: 21 }}>®</span> Business
                Deals<span style={{ fontWeight: 300, fontSize: 21 }}>®</span>{" "}
                Member!
              </div>
              <div
                className="monty"
                style={{
                  width: "90%",
                  marginTop: "30px",
                  color: "#000000",
                  fontSize: "3.2vw",
                  lineHeight: "3.6vw",
                  fontWeight: 300
                }}
              >
                Homeowners - Get a FREE* state-of-the-art smart security system
                plus 2&nbsp;FREE Premium Upgrades (up to $1,350 total value) to
                protect your home and your home business.
              </div>
              <div
                className="monty"
                style={{
                  width: "60%",
                  minWidth: "300px",
                  marginTop: "20px",
                  color: "#999999",
                  fontSize: "12px",
                  lineHeight: "15px",
                  fontWeight: 300,
                  letterSpacing: "0.01em"
                }}
              >
                *$99 installation fee and 36-month agreement (24-month in CA)
                required. Early termination fee applies.
              </div>
            </div>
          </div>
          <div style={{ clear: "both" }} />
          <div
            style={{
              width: "100%",
              backgroundColor: "#005dab",
              marginTop: "-4px",
              padding: "40px 50px 50px 50px"
            }}
          >
            <div
              className="monty"
              style={{
                padding: "0px 0px 30px 0px",
                color: "#ffffff",
                fontSize: "18px",
                lineHeight: "22px",
                fontWeight: 300
              }}
            >
              To begin, please enter your Zip Code and click Submit.
            </div>
            <form onSubmit={props.nextPage}>
              <input
                type="tel"
                name="zip"
                maxLength={5}
                placeholder="ZIP Code"
                data-corners="false"
                value={props.zipCode}
                onChange={(e) => props.setZipCode(e.target.value)}
                style={{ width: 150 }}
              />
              <button className="greenhover"
                disabled={!(/[0-9]{5}/).test(props.zipCode)}
                style={{ marginLeft: 16 }}>
                Submit
              </button>
            </form>
            <div
              className="monty"
              style={{
                marginTop: "30px",
                color: "#ffffff",
                fontSize: "17px",
                lineHeight: "32px",
                fontWeight: 300,
                letterSpacing: "0.02em"
              }}
            >
              <img
                src="/art/check.png"
                alt="Check it out"
                style={{ margin: "0px 8px -5px 0px" }}
              />
              FREE Home Security System PLUS Bonus Equipment (up to $1,350 value)
              <br />
              {/* <img src="/art/check.png" alt="Check it out" style="margin:0px 8px -5px 0px; " />FREE Bonus Equipment (up to $500 value)<br /> */}{" "}
              <img
                src="/art/check.png"
                alt="Check it out"
                style={{ margin: "0px 8px -5px 0px" }}
              />
              Choice of one video camera and one smart device
              <br />
              <img
                src="/art/check.png"
                alt="Check it out"
                style={{ margin: "0px 8px -5px 0px" }}
              />
              24/7 Professional Monitoring by the world's #1 provider
            </div>
          </div>
          {/* legal info */}
          <div
            className="montylegal"
            style={{
              backgroundColor: "#ffffff",
              textAlign: "left",
              padding: "20px 20px 20px 20px",
              width: "100%",
              maxWidth: 1000,
              borderTop: "1px #cccccc solid",
              marginTop: 20
            }}
          >
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Residential1Tablet;
