import React from 'react';
import { useNavigate } from 'react-router-dom';
import Residential4Mobile from '../components/Residential4/Mobile';
import Residential4Tablet from '../components/Residential4/Tablet';
import Residential4Desktop from '../components/Residential4/Desktop';

const Residential4 = (props) => {

  const navigate = useNavigate();

  const reset = () => {
    props.resetState();
    navigate('/residential');
  }

  return (
    <div style={{ width: "100%", marginBottom: 10 }}>
      <Residential4Mobile reset={reset} {...props} />
      <Residential4Tablet reset={reset} {...props} />
      <Residential4Desktop reset={reset} {...props} />
    </div>
  );
};

export default Residential4;
